import React from "react";
import { Navigate } from "react-router-dom";

import { useUser } from "../contexts/UserContext";

interface WithAuthProtectionProps {
  children: React.ReactNode;
}

const WithAuthProtection: React.FC<WithAuthProtectionProps> = ({
  children
}) => {
  const { isAuthenticated } = useUser();

  return isAuthenticated ? <>{children}</> : <Navigate to="/login" replace />;
};

export default WithAuthProtection;
