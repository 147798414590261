import React, {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useRef,
  useState
} from "react";

import { Close as CloseIcon } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  LinearProgress,
  Paper,
  Typography
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";

import useFileUpload, { UploadApiResponse } from "../hooks/useFileUpload";
import { Recipe, RecipeImage } from "../types/recipe";

interface ImageUploaderProps {
  uploadedImages: RecipeImage[];
  setRecipe: (recipe: (prevRecipe: Recipe) => Recipe) => void;
}

interface PreviewImageProps {
  src: string;
  onRemove: () => void;
  uploadProgress?: number;
}

interface UploadProgressBarProps {
  progress: number;
}

const UploadProgressBar: FC<UploadProgressBarProps> = ({ progress }) => {
  return (
    <Box sx={{ width: "100%" }}>
      <LinearProgress
        variant="determinate"
        color="success"
        value={progress}
        sx={{ height: "7px" }}
      />
    </Box>
  );
};

const PreviewImage: FC<PreviewImageProps> = ({
  src,
  onRemove,
  uploadProgress
}) => {
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  return (
    <Paper>
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        sx={{ padding: 1 }}
      >
        <Typography variant="body1" paddingLeft="10px">
          Preview
        </Typography>
        <IconButton size="small" onClick={onRemove}>
          <CloseIcon />
        </IconButton>
      </Grid>
      {!isLoaded && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="200px"
        >
          <CircularProgress
            color="success"
            size={50}
            value={50}
            variant="indeterminate"
          />
        </Box>
      )}
      <img
        src={src}
        onLoad={() => {
          setIsLoaded(true);
        }}
        alt="Preview"
        style={{ width: "100%", display: isLoaded ? "block" : "none" }}
      />

      {uploadProgress !== undefined && (
        <UploadProgressBar progress={uploadProgress} />
      )}
    </Paper>
  );
};

const ImageUploader: FC<ImageUploaderProps> = ({
  uploadedImages,
  setRecipe
}) => {
  const API_URL = "/v1/recipes/images";

  const uploadFile = useFileUpload();

  // use ref here to access added abort controllers from the main cleanup function
  const abortControllersRef = useRef<{ [url: string]: AbortController }>({});

  const [imageFiles, setImageFiles] = useState<{ [url: string]: File }>({});
  const [uploadProgress, setUploadProgress] = useState<{
    [url: string]: number;
  }>({});
  const [abortControllers, setAbortControllers] = useState<{
    [url: string]: AbortController;
  }>({});

  useEffect(() => {
    // Aborting all unfinished uploadings when leave the page
    return () => {
      Object.values(abortControllersRef.current).forEach((abortController) =>
        abortController.abort()
      );
      abortControllersRef.current = {};
    };
  }, []);

  useEffect(() => {
    abortControllersRef.current = abortControllers;
  }, [abortControllers]);

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const newImages: { [url: string]: File } = {};
      Array.from(e.target.files).forEach((file) => {
        const url = URL.createObjectURL(file);
        newImages[url] = file;
      });

      setImageFiles({ ...imageFiles, ...newImages });

      setUploadProgress((prevUploadProgress) => {
        Object.keys(newImages).forEach((url) => {
          prevUploadProgress[url] = 0;
        });
        return prevUploadProgress;
      });

      uploadSelectedImages(newImages);
    }
  };

  const uploadSelectedImages = (imagesToUpload: { [url: string]: File }) => {
    if (Object.keys(imagesToUpload).length > 0) {
      Object.entries(imagesToUpload).forEach(([url, file]) => {
        const abortController = new AbortController();
        setAbortControllers((prevAbortControllers) => {
          return { ...prevAbortControllers, [url]: abortController };
        });

        uploadFile(
          API_URL,
          { file },
          (progress: number) => {
            updateUploadProgress(url, progress);
          },
          (data) => {
            onUpload(url, data);
          },
          abortController
        );
      });
    }
  };

  const updateUploadProgress = (url: string, progress: number) => {
    setUploadProgress((prevUploadProgres) => {
      return { ...prevUploadProgres, [url]: progress };
    });
  };

  const onUpload = (url: string, data: UploadApiResponse) => {
    setRecipe((prevRecipe) => {
      return { ...prevRecipe, images: [...(prevRecipe["images"] || []), data] };
    });

    setAbortControllers((prevAbortControllers) => {
      const newAbortControllers = { ...prevAbortControllers };
      delete newAbortControllers[url];
      return newAbortControllers;
    });

    setImageFiles((prevImageFiles) => {
      delete prevImageFiles[url]; // Old url as a key, not the one returned from API
      return prevImageFiles;
    });
    setUploadProgress((prevUploadProgress) => {
      delete prevUploadProgress[data.file];
      return prevUploadProgress;
    });
  };

  const removeImage = async (url: string) => {
    if (url in abortControllers) {
      abortControllers[url].abort();
      const newAbortControllers = { ...abortControllers };
      delete newAbortControllers[url];
      setAbortControllers(newAbortControllers);
    }

    if (url in imageFiles) {
      const newImageFiles = { ...imageFiles };
      delete newImageFiles[url];
      setImageFiles(newImageFiles);
    } else {
      const imageId =
        uploadedImages.find((item) => item.file === url)?.id ?? null;
      if (imageId !== null) {
        setRecipe((prevRecipe) => {
          return {
            ...prevRecipe,
            images: [
              ...prevRecipe["images"].filter((item) => item.id !== imageId)
            ]
          };
        });
      }
    }
  };

  return (
    <Grid container spacing={3}>
      <Grid xs={12}>
        <input
          accept="image/*"
          type="file"
          multiple
          onChange={handleFileChange}
          style={{ display: "none" }}
          id="upload-button"
        />
        <label htmlFor="upload-button">
          <Button variant="contained" component="span">
            Select Images
          </Button>
        </label>
      </Grid>
      {uploadedImages?.map((image) => (
        <Grid key={image.file} xs={12} sm={6} md={4}>
          <PreviewImage
            src={image.file}
            onRemove={() => removeImage(image.file)}
          />
        </Grid>
      ))}
      {Object.keys(imageFiles).map((url) => (
        <Grid key={url} xs={12} sm={6} md={4}>
          <PreviewImage
            src={url}
            onRemove={() => removeImage(url)}
            uploadProgress={uploadProgress[url]}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default ImageUploader;
