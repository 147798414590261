import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";
import React, {
  RefCallback,
  useCallback,
  useEffect,
  useRef,
  useState
} from "react";
import { Outlet, useOutletContext } from "react-router-dom";

import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";

import { WeeklyMenuOutletProps } from "../components/LayoutMenu";
import { CardRef, DraggableRecipeCard } from "../components/RecipeCard";
import useRecipes from "../hooks/useRecipes";
import { ItemTypes } from "../types/DnD";
import { Recipe } from "../types/recipe";

dayjs.extend(weekday);
const RECIPES_FETCH_LIMIT = 20;

const MenuPlannerPage = () => {
  const sortFields = {
    title: "Title – A to Z",
    "-title": "Title – Z to A",
    "-rating": "Rating – High to Low",
    rating: "Rating – Low to Hight",
    created_ts: "Created – Newest to Oldest",
    "-created_ts": "Created – Oldest to Newest",
    updated_ts: "Updated – Newest to Oldest",
    "-updated_ts": "Updated – Oldest to Newest"
  };

  const pageProps = useOutletContext<WeeklyMenuOutletProps>();

  const recipesIntObserver = useRef<IntersectionObserver | null>(null);
  const { getMyRecipes, loading } = useRecipes();

  const [recipes, setRecipes] = useState<Recipe[]>([]);
  const [nextOffset, setNextOffset] = useState<number | null>(0);
  const [filterTerm, setFilterTerm] = useState<string>("");
  const [loadMore, setloadMore] = useState<boolean>(true);
  const [sortField, setSortField] = useState<keyof typeof sortFields>("title");

  const fetchRecipes = async (
    term: string,
    offset: number | null,
    sortField: keyof typeof sortFields
  ) => {
    console.log(
      `Fetching recipes name starting with ${term} (offset ${offset})`
    );
    if (offset !== null) {
      const paginatedRecipes = await getMyRecipes(
        term,
        offset,
        RECIPES_FETCH_LIMIT,
        sortField
      );
      setRecipes([...recipes, ...paginatedRecipes.results]);
      setNextOffset(paginatedRecipes.offset);
    }
  };

  useEffect(() => {
    if (filterTerm !== undefined) {
      console.log(filterTerm);
      setRecipes([]);
      setNextOffset(0);
      setloadMore(true);
    }
  }, [filterTerm, sortField]);

  useEffect(() => {
    if (nextOffset !== null && loadMore) {
      fetchRecipes(filterTerm, nextOffset, sortField);
      setloadMore(false);
    }
  }, [nextOffset, loadMore]);

  const recipeLastRef: RefCallback<CardRef | null> = useCallback(
    (card: CardRef) => {
      if (loading || loadMore) {
        return;
      }
      if (recipesIntObserver.current) {
        recipesIntObserver.current.disconnect();
      }

      recipesIntObserver.current = new IntersectionObserver(
        (entries: IntersectionObserverEntry[]) => {
          if (entries[0].isIntersecting && nextOffset !== null) {
            setloadMore(true);
          }
        }
      );

      if (card) {
        recipesIntObserver.current.observe(card);
      }
    },
    [loading, loadMore, nextOffset]
  );

  return (
    <>
      <Grid xs={12}>
        {/* Menu planner days with recipes list */}
        <Outlet context={pageProps} />
      </Grid>

      <Grid
        xs={12}
        order={{ xs: 1, md: 1 }}
        sx={{ overflowY: "auto", height: "100%", mt: -3 }}
      >
        <Stack
          direction="row"
          spacing={2}
          display="flex"
          justifyContent="center"
          alignItems="center"
          marginY="20px"
        >
          <TextField
            label="Filter recipes"
            value={filterTerm}
            onChange={(e) => setFilterTerm(e.target.value)}
            variant="outlined"
            sx={{ width: { xs: "80%", md: "600px" } }}
          />
          <FormControl sx={{ minWidth: 140 }}>
            <InputLabel>Sort by field</InputLabel>
            <Select
              value={sortField}
              label="Sort by field"
              onChange={(e: SelectChangeEvent) => {
                setSortField(e.target.value as keyof typeof sortFields);
              }}
            >
              {Object.entries(sortFields).map(([key, value]) => {
                return (
                  <MenuItem key={key} value={key}>
                    {value}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Stack>
        <Grid
          container
          disableEqualOverflow
          spacing={2}
          justifyContent="center"
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fit, minmax(220px, auto))"
          }}
        >
          {recipes.map((recipe, idx) => {
            return (
              <Grid disableEqualOverflow key={recipe.id}>
                <DraggableRecipeCard
                  recipe={recipe}
                  itemType={ItemTypes.MenuPlannerRecipe}
                  {...(idx === recipes.length - 1
                    ? { ref: recipeLastRef }
                    : {})}
                />
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </>
  );
};

export default MenuPlannerPage;
