import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";

import { LoadingButton } from "@mui/lab";
import {
  FormControl,
  FormHelperText,
  Grid,
  Paper,
  TextField,
  Typography
} from "@mui/material";

import { useUser } from "../contexts/UserContext";
import { useAuth } from "../hooks/useAuth";

const SignUp: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const { register, error } = useAuth();
  const { isAuthenticated } = useUser();

  const [loading, setLoading] = useState<boolean>(false);
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [usernameError, setUsernameError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [authError, setAuthError] = useState("");
  const [customUsername, setCustomUsername] = useState<boolean>(false);

  useEffect(() => {
    if (email === undefined) {
      return;
    }

    if (!customUsername) {
      const splited = email.split("@");
      setUsername(splited[0] ?? "");
    }
  }, [email]);

  // Redirect to the index page if isAuthenticated is true
  if (isAuthenticated) {
    return <Navigate to="/" />;
  }

  const handleChangeUsername = (val: string) => {
    setCustomUsername(true);
    setUsername(val);
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    setEmailError(false);
    setUsernameError(false);
    setPasswordError(false);
    setAuthError("");

    let validationError = false;

    if (email === "") {
      setEmailError(true);
      validationError = true;
    }

    if (username === "") {
      setUsernameError(true);
      validationError = true;
    }

    if (password === "") {
      setPasswordError(true);
      validationError = true;
    }

    if (!validationError) {
      setLoading(true);
      try {
        await register(email, username, password);
        enqueueSnackbar("Account created, please login!", {
          variant: "success"
        });
        navigate("/login");
      } catch (error) {
        console.error("Register error:", error);
        setAuthError("Registration failed.");
      } finally {
        setLoading(false);
      }
      setAuthError("");
    }
  };

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} sm={8} md={6} lg={4}>
        <Paper elevation={3} sx={{ p: 4, my: 4 }}>
          <Typography variant="h5" component="h1" gutterBottom>
            Sign Up
          </Typography>
          <form onSubmit={handleSubmit}>
            <FormControl fullWidth error={emailError} margin="normal">
              <TextField
                label="Email"
                variant="outlined"
                error={!!error?.email || emailError}
                helperText={error?.email}
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              {emailError && (
                <FormHelperText>Email is required.</FormHelperText>
              )}
            </FormControl>
            <FormControl fullWidth error={usernameError} margin="normal">
              <TextField
                label="Username"
                variant="outlined"
                error={!!error?.username || usernameError}
                helperText={error?.username}
                type="text"
                value={username}
                onChange={(e) => handleChangeUsername(e.target.value)}
              />
              {usernameError && (
                <FormHelperText>Username is required.</FormHelperText>
              )}
            </FormControl>
            <FormControl fullWidth error={passwordError} margin="normal">
              <TextField
                label="Password"
                variant="outlined"
                error={!!error?.password || passwordError}
                helperText={error?.password}
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              {passwordError && (
                <FormHelperText>Password is required.</FormHelperText>
              )}
            </FormControl>
            {authError && (
              <Typography color="error" variant="body2">
                {authError}
              </Typography>
            )}
            <LoadingButton
              size="small"
              type="submit"
              loading={loading}
              variant="contained"
              color="primary"
              fullWidth
            >
              <span>Sign Up</span>
            </LoadingButton>
          </form>
          <Typography variant="body2" sx={{ mt: 2 }}>
            Already have an account? <Link to="/login">Login</Link>
          </Typography>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default SignUp;
