import { Dayjs } from "dayjs";
import React, { useEffect, useState } from "react";

import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow
} from "@mui/material";

import DayServings from "../components/DayServings";
import { useProfile } from "../contexts/ProfileContext";
import useDateUtils from "../hooks/useDateUtils";
import { Day, WeekServings } from "../types/menu";

const ServingsConfigurationPage: React.FC = () => {
  const { getCurrentWeekDates } = useDateUtils();
  const { weekStart, updateServingsConfiguration, profile } = useProfile();

  const [servings, setServings] = useState<WeekServings | null>(
    profile?.servings || null
  );
  const [weekDays, setWeekDays] = useState<Dayjs[]>([]);

  useEffect(() => {
    const weekDays = getCurrentWeekDates();
    setWeekDays(weekDays);
  }, [weekStart]);

  const handleChange = (day: Day, serving: number) => {
    setServings((prevState) =>
      prevState ? { ...prevState, [day]: serving } : null
    );
  };

  const handleSubmit = async () => {
    // Call API with servings object
    if (servings === null) {
      return;
    }
    updateServingsConfiguration(servings);
  };

  return (
    <Box sx={{ maxWidth: "260px" }}>
      <TableContainer component={Paper}>
        <Table>
          <TableBody>
            {weekDays.map((day, index) => (
              <TableRow key={index}>
                <TableCell>{day.format("dddd")}</TableCell>
                <TableCell align="right">
                  <DayServings
                    value={servings ? servings[day.day() as Day] : 0}
                    onChange={(servings: number) =>
                      handleChange(day.day() as Day, servings)
                    }
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Button
        variant="contained"
        color="primary"
        onClick={handleSubmit}
        sx={{ marginTop: "20px" }}
      >
        Save
      </Button>
    </Box>
  );
};

export default ServingsConfigurationPage;
