import { SnackbarProvider } from "notistack";
import React, { FC, ReactElement } from "react";
import { HTML5Backend } from "react-dnd-html5-backend";
import {
  DndProvider,
  MouseTransition,
  Preview,
  PreviewState,
  TouchTransition
} from "react-dnd-multi-backend";
import { TouchBackend } from "react-dnd-touch-backend";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes
} from "react-router-dom";

import { Box, CssBaseline } from "@mui/material";

import Layout from "./components/Layout";
import LayoutMenu from "./components/LayoutMenu";
import Login from "./components/Login";
import MenuPlannerDaysBlock from "./components/MenuPlannerDaysBlock";
import SignUp from "./components/SignUp";
import WithAuthProtection from "./components/WithAuthProtection";
import { ApiProvider } from "./contexts/ApiContext";
import CollaborationProvider from "./contexts/CollaborationContext";
import ThemeControlProvider from "./contexts/CustomThemeContext";
import ProfileProvider from "./contexts/ProfileContext";
import { UnitsProvider } from "./contexts/UnitsContext";
import { UserProvider } from "./contexts/UserContext";
import AccountPage from "./pages/AccountPage";
import AddRecipePage from "./pages/AddRecipePage";
import CollaborationPage from "./pages/CollaborationPage";
import CookBookPage from "./pages/CookBookPage";
import EditRecipePage from "./pages/EditRecipePage";
import MenuPlannerPage from "./pages/MenuPlannerPage";
import NotFoundPage from "./pages/NotFoundPage";
import RecipeDetailsPage from "./pages/RecipeDetailsPage";
import ServingsConfigurationPage from "./pages/ServingsConfigurationPage";
import SettingsPage from "./pages/SettingsPage";
import WeekDayMenuRecipePage from "./pages/WeekDayMenuRecipePage";
import WeeklyMenuPage from "./pages/WeeklyMenuPage";
import { Recipe } from "./types/recipe";

const About = () => <div>About</div>;

export const HTML5toTouch = {
  backends: [
    {
      id: "html5",
      backend: HTML5Backend,
      transition: MouseTransition,
      delay: 2000, // prevent accidental click
      preview: false
    },
    {
      id: "touch",
      backend: TouchBackend,
      options: {
        enableMouseEvents: true,
        delayTouchStart: 30, // The amount in ms to delay processing of touch events
        touchSlop: 10 // Specifies the pixel distance moved before a drag is signaled.
      },
      preview: true,
      transition: TouchTransition
    }
  ]
};

const generatePreview = ({
  item,
  style,
  monitor
}: PreviewState<Recipe>): ReactElement => {
  const { x } = monitor.getSourceClientOffset() ?? { x: 0 };
  const image = item.images[0]?.file || "/images/fallback_dall_e.png";
  return (
    <Box sx={{ ...style, left: x, zIndex: 100, opacity: 0.8 }}>
      <img width="250px" src={image} alt="Preview" />
    </Box>
  );
};

const App: FC = () => {
  return (
    <ThemeControlProvider>
      <SnackbarProvider
        autoHideDuration={3000}
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
        preventDuplicate={false}
      >
        <DndProvider options={HTML5toTouch}>
          <Preview>{generatePreview}</Preview>
          <ApiProvider>
            <UserProvider>
              <ProfileProvider>
                <CollaborationProvider>
                  <UnitsProvider>
                    <Router>
                      <CssBaseline />
                      <Layout>
                        <Routes>
                          <Route
                            path="/"
                            element={<Navigate replace to="/menu" />}
                          />
                          <Route
                            path="/menu/:username?/:year?/:week?/*"
                            element={
                              <WithAuthProtection>
                                <MenuRoutes />
                              </WithAuthProtection>
                            }
                          />
                          <Route
                            path="/menu/:username/:year/:week/:day/recipe/:recipeId"
                            element={
                              <WithAuthProtection>
                                <WeekDayMenuRecipePage />
                              </WithAuthProtection>
                            }
                          />
                          <Route
                            path="/cook-book/*"
                            element={
                              <WithAuthProtection>
                                <CookBookRoutes />
                              </WithAuthProtection>
                            }
                          />
                          <Route
                            path="/account/*"
                            element={
                              <WithAuthProtection>
                                <AccountRoutes />
                              </WithAuthProtection>
                            }
                          />
                          <Route path="/about" element={<About />} />
                          <Route path="/login" element={<Login />} />
                          <Route path="/signup" element={<SignUp />} />
                          <Route path="*" element={<NotFoundPage />} />
                        </Routes>
                      </Layout>
                    </Router>
                  </UnitsProvider>
                </CollaborationProvider>
              </ProfileProvider>
            </UserProvider>
          </ApiProvider>
        </DndProvider>
      </SnackbarProvider>
    </ThemeControlProvider>
  );
};

function CookBookRoutes() {
  return (
    <Routes>
      <Route path="/" element={<CookBookPage />} />
      <Route path="add" element={<AddRecipePage />} />
      <Route path="public" element={<Navigate replace to="/cook-book" />} />
      <Route path="public/:recipeId/*" element={<PublicRecipeRoutes />} />
      <Route path=":recipeId/*" element={<UserRecipeRoutes />} />
    </Routes>
  );
}

function MenuRoutes() {
  return (
    <Routes>
      <Route path="" element={<LayoutMenu />}>
        <Route path="" element={<WeeklyMenuPage />} />
        <Route path="/edit" element={<MenuPlannerPage />}>
          <Route path="" element={<MenuPlannerDaysBlock />} />
        </Route>
      </Route>
    </Routes>
  );
}

function UserRecipeRoutes() {
  return (
    <Routes>
      <Route path="" element={<RecipeDetailsPage />} />
      <Route path="/edit" element={<EditRecipePage />} />
    </Routes>
  );
}

function PublicRecipeRoutes() {
  return (
    <Routes>
      <Route path="" element={<RecipeDetailsPage publicRecipe={true} />} />
    </Routes>
  );
}

const AccountRoutes = () => {
  return (
    <Routes>
      <Route path="" element={<AccountPage />}>
        <Route path="/collaboration" element={<CollaborationPage />} />
        <Route path="/servings" element={<ServingsConfigurationPage />} />
        <Route path="/settings" element={<SettingsPage />} />
      </Route>
    </Routes>
  );
};

export default App;
