import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState
} from "react";

import { PaletteMode } from "@mui/material";
import { Theme, ThemeProvider as MuiThemeProvider } from "@mui/material/styles";

import createMyTheme from "../theme";
import { Mode } from "../types/theme";

export const COLOR_SCHEME_STORAGE_KEY = "prefers-color-scheme";

const getCurrentSystemColorScheme = () => {
  const prefersDarkMode = window.matchMedia(
    "(prefers-color-scheme: dark)"
  ).matches;
  return prefersDarkMode ? Mode.DARK : Mode.LIGHT;
};

const getCachedColorSchemeMode = (): PaletteMode => {
  // Get preferred color scheme mode from local storage, if it's set.
  // If not set in the local storage at all then return "light" mode.
  // If set to "system" then read what currently system mode is and return it.
  let mode: Mode | null =
    (localStorage.getItem(COLOR_SCHEME_STORAGE_KEY) as Mode) ?? null;
  if (mode === Mode.SYSTEM || mode === null) {
    mode = getCurrentSystemColorScheme();
  }
  return mode as PaletteMode;
};

interface ThemeControlContextProps {
  theme: Theme;
  updatePreferredMode: (mode: Mode) => void;
}

const ThemeControlContext = createContext<ThemeControlContextProps | undefined>(
  {
    theme: createMyTheme("light"),
    updatePreferredMode: (mode: Mode) => undefined
  }
);

interface ThemeControlProviderProps {
  children: ReactNode;
}

const ThemeControlProvider: React.FC<ThemeControlProviderProps> = ({
  children
}) => {
  const [mode, setMode] = useState<PaletteMode>(getCachedColorSchemeMode()); // current scheme mode
  const [preferredMode, setPreferredMode] = useState<Mode | null>(null); // user selected scheme mode

  // Update the theme mode state when the system theme changes
  useEffect(() => {
    if (preferredMode === null) {
      return;
    }

    if (preferredMode !== Mode.SYSTEM) {
      setMode(preferredMode);
      return;
    }
    // set event listener to update once the system color scheme changes
    const colorSchemeQuery = window.matchMedia("(prefers-color-scheme: dark)");
    const updateMode = (e: MediaQueryListEvent) => {
      setMode(e.matches ? Mode.DARK : Mode.LIGHT);
    };
    colorSchemeQuery.addEventListener("change", updateMode);

    // still need to read the current system color scheme and update accordingly
    setMode(getCurrentSystemColorScheme());

    // Cleanup function
    return () => {
      if (colorSchemeQuery === undefined) {
        return;
      }
      colorSchemeQuery.removeEventListener("change", updateMode);
    };
  }, [preferredMode]);

  const updatePreferredMode = (mode: Mode) => {
    // Set mode to local storage to evetually read it every time dite loads
    localStorage.setItem(COLOR_SCHEME_STORAGE_KEY, mode);
    setPreferredMode(mode);
  };

  const theme = React.useMemo(() => createMyTheme(mode as PaletteMode), [mode]);

  return (
    <ThemeControlContext.Provider value={{ theme, updatePreferredMode }}>
      <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
    </ThemeControlContext.Provider>
  );
};

export const useThemeControl = (): ThemeControlContextProps => {
  const context = useContext(ThemeControlContext);
  if (!context) {
    throw new Error(
      "useThemeControl must be used within a ThemeControlProvider"
    );
  }
  return context;
};

export default ThemeControlProvider;
