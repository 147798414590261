import React, { FC, useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";

import { Stack } from "@mui/material";

import { useCollaboration } from "../contexts/CollaborationContext";
import { useProfile } from "../contexts/ProfileContext";
import useDateUtils from "../hooks/useDateUtils";
import useMenus from "../hooks/useMenus";
import { Day, WeekServings } from "../types/menu";
import { Recipe } from "../types/recipe";
import { DayOfWeek } from "./DayOfWeek";
import { generateMenuUrl, WeeklyMenuOutletProps } from "./LayoutMenu";
import LoadingAnimation from "./LoadingAnimation";

const MenuPlannerDaysBlock: FC = () => {
  const {
    year,
    week,
    weekMenu,
    setWeekMenu,
    setDateBarButtonText,
    setDateBarButtonLink,
    setDateBarUrlSuffix
  } = useOutletContext<WeeklyMenuOutletProps>();

  const { activeCollaboratorUser } = useCollaboration();
  const { getWeekDates } = useDateUtils();
  const { profile } = useProfile();
  const {
    addRecipeToDayMenu,
    deleteRecipeFromMenu,
    getWeekServings,
    updateWeekServings
  } = useMenus(activeCollaboratorUser.id);

  const [weekServings, setWeekServings] = useState<WeekServings | null>(
    profile?.servings || null
  );

  useEffect(() => {
    const fetchServings = async (year: number, week: number) => {
      if (profile === null) {
        return;
      }
      const servings = await getWeekServings(year, week);
      const servingsParsed: WeekServings = { ...profile.servings };
      servings.forEach((item) => {
        servingsParsed[item.day_number] = item.number;
      });
      setWeekServings(servingsParsed);
    };

    setDateBarButtonText("Close");
    setDateBarButtonLink(
      generateMenuUrl(
        activeCollaboratorUser.username,
        year.toString(),
        week.toString()
      )
    );
    setDateBarUrlSuffix("edit");
    fetchServings(year, week);
  }, [year, week, activeCollaboratorUser, profile]);

  const handleAdd = async (
    year: number,
    week: number,
    day: number,
    recipe: Recipe
  ) => {
    if (recipe === null) {
      return;
    }
    const newItem = await addRecipeToDayMenu(year, week, day, recipe?.id || 0);
    setWeekMenu((prevWeekMenu) => {
      return {
        ...prevWeekMenu,
        [day]: [...(prevWeekMenu[day] ? prevWeekMenu[day] : []), newItem]
      };
    });
  };

  const handleDelete = async (
    year: number,
    week: number,
    day: number,
    recipeId: number
  ) => {
    const res: boolean = await deleteRecipeFromMenu(year, week, day, recipeId);
    if (!res) {
      return;
    }
    setWeekMenu((prevWeekMenu) => {
      return {
        ...prevWeekMenu,
        [day]: prevWeekMenu[day]
          ? prevWeekMenu[day].filter((item) => item.recipe !== recipeId)
          : []
      };
    });
  };

  const handleServingsChange = async (day: Day, servings: number) => {
    setWeekServings((prevState) =>
      prevState ? { ...prevState, [day]: servings } : null
    );
    await updateWeekServings(year, week, day, servings);
  };

  if (year === null || week === null) {
    return <LoadingAnimation />;
  }

  return (
    <>
      {/*day items */}
      <Stack
        direction="row"
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(7, 1fr)",
          top: 0,
          position: "sticky",
          zIndex: 5,
          bgcolor: "background.default",
          pt: "1rem",
          pb: "1rem"
        }}
      >
        {getWeekDates(year, week).map((day) => (
          <DayOfWeek
            key={day.format()}
            year={year}
            week={week}
            day={day}
            servings={weekServings ? weekServings[day.day() as Day] : 0}
            recipesList={weekMenu[day.day()] || []}
            handleServingsChange={(servings: number) =>
              handleServingsChange(day.day() as Day, servings)
            }
            handleAdd={handleAdd}
            handleDelete={handleDelete}
          />
        ))}
      </Stack>
    </>
  );
};

export default MenuPlannerDaysBlock;
