import { AxiosError } from "axios";
import React, { useCallback, useState } from "react";

import useApi from "../contexts/ApiContext";
import { useUserDispatch } from "../contexts/UserContext";
import { User } from "../types/user";

interface LoginResponse {
  user: User;
  expiry: string;
  token: string;
}

interface RegisterResponse extends LoginResponse {}

export function useAuth() {
  const api = useApi();
  const dispatch = useUserDispatch();

  // Add an error state
  const [error, setError] = useState<{
    non_field_errors?: string;
    email?: string;
    username?: string;
    password?: string;
  } | null>(null);

  const loginUser = async (
    email: string,
    password: string
  ): Promise<LoginResponse> => {
    const requestUrl = "/v1/auth/login/";
    setError(null);

    const { request } = api.post<LoginResponse>(requestUrl, {
      email,
      password
    });
    return (await request).data;
  };

  const registerUser = async (
    email: string,
    username: string,
    password: string
  ): Promise<RegisterResponse> => {
    const requestUrl = "/v1/auth/register/";
    setError(null);
    const { request } = api.post<RegisterResponse>(requestUrl, {
      email,
      username,
      password
    });
    return (await request).data;
  };

  const logoutUser = async (): Promise<void> => {
    const requestUrl = "/v1/auth/logout/";
    const { request } = api.post(requestUrl, {});
    await request;
  };

  const login = useCallback(
    async (email: string, password: string) => {
      try {
        const response = await loginUser(email, password);
        const user = { ...response.user, token: response.token };
        dispatch({ type: "LOGIN", payload: user });
      } catch (error) {
        let errorMessage = { non_field_errors: "Authentication error." };
        if (error instanceof AxiosError) {
          errorMessage = { ...errorMessage, ...(error.response?.data || {}) };
        }

        // Parse and set the error object
        setError(errorMessage);
      }
    },
    [dispatch]
  );

  const register = useCallback(
    async (email: string, username: string, password: string) => {
      try {
        await registerUser(email, username, password);
        dispatch({ type: "REGISTER", payload: {} });
      } catch (error) {
        let errorMessage = { non_field_errors: "Registration error." };
        if (error instanceof AxiosError) {
          errorMessage = { ...errorMessage, ...(error.response?.data || {}) };
        }
        // Parse and set the error object
        setError(errorMessage);
        throw error;
      }
    },
    [dispatch]
  );

  const logout = useCallback(async () => {
    logoutUser();
    dispatch({ type: "LOGOUT" });
  }, [dispatch]);

  return { login, register, logout, error };
}
