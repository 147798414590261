import { Dayjs } from "dayjs";
import React, { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import DeleteIcon from "@mui/icons-material/Delete";
import {
  Avatar,
  Dialog,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Stack,
  Typography
} from "@mui/material";

import { WeekDayMenu } from "../types/menu";
import { Recipe } from "../types/recipe";

export interface MoreModal {
  open: boolean;
  onClose: (value: string) => void;
  onDelete: (recipeId: number) => void;
  recipesList: WeekDayMenu[];
  day: Dayjs;
}

export const MoreModal: FC<MoreModal> = ({
  open,
  onClose,
  onDelete,
  recipesList,
  day
}: MoreModal) => {
  const imageUrl = "/images/fallback_dall_e.png";

  const navigate = useNavigate();

  const handleClick = (recipeId: number) => {
    navigate(`/cook-book/${recipeId}`);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle sx={{ height: "3.5em" }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ mb: 2 }}
        >
          <Avatar
            variant="rounded"
            alt={day.format("ddd").toUpperCase()}
            sx={{
              fontSize: "1em",
              color: "text.primary"
            }}
          >
            {day.format("ddd")}{" "}
          </Avatar>
          <Typography sx={{ color: "#70757a", fontStyle: "italic" }}>
            {day.format("	MMM D")}
          </Typography>
        </Stack>
      </DialogTitle>
      <List
        sx={{
          width: "100%",
          maxWidth: 360,
          bgcolor: "background.paper",
          pb: 5
        }}
      >
        {recipesList.map((recipe, idx) => (
          <ListItem
            key={idx}
            secondaryAction={
              <IconButton
                onClick={() => onDelete(recipe.recipe)}
                aria-label="delete"
                size="small"
              >
                <DeleteIcon fontSize="inherit" />
              </IconButton>
            }
            disablePadding
          >
            <ListItemButton dense onClick={() => handleClick(recipe.recipe)}>
              <ListItemAvatar>
                <Avatar alt={recipe.title} src={imageUrl} />
              </ListItemAvatar>
              <Typography variant="inherit" noWrap>
                {recipe.title}
              </Typography>
              <ListItemText></ListItemText>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
};
