// src/pages/RecipeDetailsPage.tsx
import React, { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import LoadingAnimation from "../components/LoadingAnimation";
import RecipeDetails from "../components/RecipeDetails";
import { useRecipes } from "../hooks/useRecipes";
import { Recipe } from "../types/recipe";
import NotFoundPage from "./NotFoundPage";

type PageParams = {
  recipeId: string;
};

interface RecipeDetailsPageProps {
  publicRecipe?: boolean;
}

const RecipeDetailsPage: FC<RecipeDetailsPageProps> = ({ publicRecipe }) => {
  const { recipeId } = useParams<PageParams>();

  const { getRecipeById, deleteRecipeById, loading } = useRecipes();

  const [recipe, setRecipe] = useState<Recipe>();

  const fetchRecipe = async (recipeId: string | number) => {
    const data = await getRecipeById(recipeId, publicRecipe ?? false);
    setRecipe(data);
  };

  useEffect(() => {
    if (recipeId) {
      fetchRecipe(recipeId);
    }
  }, []);

  if (loading) {
    return <LoadingAnimation />;
  }

  // TODO test if the below condition can simplified (remove !loading)
  if (!loading && !recipe) {
    return <NotFoundPage />;
  }

  if (loading || !recipe) {
    return <LoadingAnimation />;
  }

  return (
    <RecipeDetails
      recipe={recipe}
      onDelete={deleteRecipeById}
      publicRecipe={publicRecipe}
    />
  );
};

export default RecipeDetailsPage;
