export enum Action {
  POST = "POST",
  PUT = "PUT",
}

export enum FieldType {
  CHOICE = "choice",
}

export type Value = string | number;

export interface ApiOptions {
  name: string,
  description: string,
  actions: {
    [name in Action]: ApiFields
  }
}

export interface ApiFields {
  [field: string]: FieldOptions,
}

export interface FieldOptions {
  type: FieldType,
  required: false | true,
  read_only: false | true,
  label: string,
  choices?: FieldChoicesOptions[],
}

export interface FieldChoicesOptions {
  value: string | number,
  display_name: string,
}

export interface FieldValues {
  [name: string]: string | number,
}

export interface ApiResponeError {
  [field: string]: string[] | ApiResponeError[],
}