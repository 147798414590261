import React, { FC, ReactNode } from "react";

import { Container } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { useProfile } from "../contexts/ProfileContext";
import { useUser } from "../contexts/UserContext";
import Breadcrumbs from "./Breadcrumbs";
import LoadingAnimation from "./LoadingAnimation";
import TopNavBar from "./TopNavBar";

interface LayoutProps {
  children: ReactNode;
}

const Layout: FC<LayoutProps> = ({ children }) => {
  const { isAuthenticated } = useUser();
  const { profile } = useProfile();

  if (isAuthenticated && profile === null) {
    // Workaround needed to set a correct date start day (in ProfileContext)
    return <LoadingAnimation />;
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <TopNavBar />
      <Container
        maxWidth="xl"
        sx={{ paddingTop: "20px", height: "calc(100% - 64px)" }}
      >
        {isAuthenticated && <Breadcrumbs />}
        {children}
      </Container>
    </LocalizationProvider>
  );
};

export default Layout;
