import React, { useEffect } from "react";
import { useOutletContext } from "react-router-dom";

import Grid from "@mui/material/Unstable_Grid2/Grid2";

import DayRecipes from "../components/DayRecipes";
import { generateMenuUrl, WeeklyMenuOutletProps } from "../components/LayoutMenu";
import { useCollaboration } from "../contexts/CollaborationContext";

const WeeklyMenuPage = () => {
  const {
    year,
    week,
    weekMenu,
    weekDates,
    setDateBarButtonText,
    setDateBarButtonLink,
    setDateBarUrlSuffix
  } = useOutletContext<WeeklyMenuOutletProps>();

  const { activeCollaboratorUser } = useCollaboration();

  useEffect(() => {
    setDateBarButtonText("Edit Menu");
    setDateBarButtonLink(
      generateMenuUrl(
        activeCollaboratorUser.username,
        year.toString(),
        week.toString(),
        "edit"
      )
    );
    setDateBarUrlSuffix("");
  }, [year, week, activeCollaboratorUser]);

  return (
    <>
      {weekDates.map((date) => (
        <Grid key={date.day()} xs={12} sm={6} md={4}>
          <DayRecipes
            activeCollaboratorUser={activeCollaboratorUser}
            year={year}
            week={week}
            date={date}
            recipes={weekMenu[date.day()] || []}
          />
        </Grid>
      ))}
    </>
  );
};

export default WeeklyMenuPage;
