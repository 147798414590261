import { useCallback } from "react";

import { Fraction } from "../types/recipe";

interface MathFunctions {
  decimalToFraction: (decimal: number) => Fraction;
  stringToFraction: (str: string) => Fraction;
  fractionToString: (fraction: Fraction) => string;
  isValidDecimalOrFraction: (str: string) => boolean;
}

const useMathFunctions = (): MathFunctions => {
  const pattern: RegExp = /^(?:(\d+(\.\d*)?|(\d+\s)?\d+\/\d+|\d+\s))$/;
  const maxNumber: number = 32767; // smallint 	2 bytes  -32768 to +32767

  const _gcd = (a: number, b: number): number => {
    return b ? _gcd(b, a % b) : a;
  };

  const decimalToFraction = useCallback((decimal: number): Fraction => {
    if (isNaN(decimal)) {
      return { numerator: 0, denominator: 1 };
    }
    let numerator = decimal;
    let denominator = 1;

    while (numerator % 1 !== 0) {
      numerator *= 10;
      denominator *= 10;
    }

    const divisor = _gcd(numerator, denominator);

    return {
      numerator: numerator / divisor,
      denominator: denominator / divisor
    };
  }, []);

  const stringToFraction = useCallback((str: string): Fraction => {
    let wholeNumber = 0;
    let fraction = str;

    if (str.includes(" ")) {
      const parts = str.split(" ");
      wholeNumber = parseInt(parts[0]);
      fraction = parts[1];
    }

    const [numerator, denominator] = fraction.split("/").map(Number);
    const finalNumerator = wholeNumber * (denominator || 1) + numerator;
    const finalDenominator = denominator || 1;

    return { numerator: finalNumerator, denominator: finalDenominator };
  }, []);

  const fractionToString = useCallback((fraction: Fraction): string => {
    const { numerator, denominator } = fraction;

    if (numerator === 0) {
      return "0";
    }

    const wholeNumber = Math.floor(numerator / denominator);
    const remainder = numerator % denominator;

    if (wholeNumber && remainder) {
      return `${wholeNumber} ${remainder}/${denominator}`;
    } else if (wholeNumber) {
      return `${wholeNumber}`;
    } else {
      return `${numerator}/${denominator}`;
    }
  }, []);

  const isValidDecimalOrFraction = (str: string): boolean => {
    if (pattern.test(str)) {
      const fraction = stringToFraction(str);
      return (
        fraction.numerator <= maxNumber && fraction.denominator <= maxNumber
      );
    }
    return false;
  };

  return {
    decimalToFraction,
    stringToFraction,
    fractionToString,
    isValidDecimalOrFraction
  };
};

export default useMathFunctions;
