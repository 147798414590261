import dayjs from "dayjs";
import updateLocale from "dayjs/plugin/updateLocale";
import { useSnackbar } from "notistack";
import React, {
  createContext,
  FC,
  ReactNode,
  useContext,
  useEffect,
  useState
} from "react";

import { ApiFields, ApiOptions, FieldValues } from "../types/api";
import { OutInvite } from "../types/collaboration";
import { WeekServings } from "../types/menu";
import { Profile } from "../types/profile";
import { Mode } from "../types/theme";
import useApi from "./ApiContext";
import { useThemeControl } from "./CustomThemeContext";
import { useUser } from "./UserContext";

dayjs.extend(updateLocale);

export type WeekFirstDay = "Sunday" | "Monday";

interface ProfileContextProps {
  // Define the shape of the profile here
  profileFieldOptions: ApiFields;
  loading: boolean;
  weekStart: number;
  profile: Profile | null;
  setProfile: (profile: Profile) => void;
  saveProfile: () => Promise<void>;
  updateServingsConfiguration: (servings: WeekServings) => Promise<void>;
}

interface ProfileProviderProps {
  children: ReactNode;
}

// Create the context with default values
const ProfileContext = createContext<ProfileContextProps>({
  profileFieldOptions: {} as ApiFields,
  loading: false,
  weekStart: 0,
  profile: ({} as Profile) || null,
  setProfile: (profile: Profile) => undefined,
  saveProfile: () => Promise.resolve(undefined),
  updateServingsConfiguration: ({}) => Promise.resolve(undefined)
});

const ProfileProvider: FC<ProfileProviderProps> = ({ children }) => {
  const API_URL = "/v1/profile";

  const { enqueueSnackbar } = useSnackbar();

  const api = useApi();
  const { updatePreferredMode } = useThemeControl();
  const { user, isAuthenticated } = useUser();

  const [profileFieldOptions, setProfileFieldOptions] = useState<ApiFields>({});
  const [profile, setProfile] = useState<Profile | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [weekStart, setWeekStart] = useState<number>(0);

  useEffect(() => {
    // Lad profile from the server here
    if (isAuthenticated) {
      setLoading(true);
      loadProfile();
      setLoading(false);
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (profile !== null) {
      updateStartWeekDay(profile.week_start_day as WeekFirstDay);
    }
  }, [profile]);

  const loadProfile = async () => {
    console.log(`Loading profile for user ${user?.email}`);
    const requestUrl = `${API_URL}/`;
    try {
      const { request } = api.options<ApiOptions>(requestUrl);
      const response = await request;

      const profileOptions = response.data;
      setProfileFieldOptions({
        ...(profileOptions.actions.POST || {}),
        ...(profileOptions.actions.PUT || {})
      });
    } catch (e) {
      throw e;
    }

    try {
      const { request } = api.get<Profile>(requestUrl);
      const profile = (await request).data;
      updatePreferredMode(profile.color_scheme as Mode);
      setProfile(profile);
    } catch (e) {
      throw e;
    }
  };

  const saveProfile = async () => {
    const requestUrl = `${API_URL}/`;
    if (profile === null) {
      return;
    }
    try {
      const { request } = api.put<Profile>(requestUrl, profile);
      const updatedPofile = (await request).data;
      updatePreferredMode(updatedPofile.color_scheme as Mode);
      setProfile(updatedPofile);
      enqueueSnackbar("Setting saved!", { variant: "success" });
    } catch (e) {
      throw e;
    }
  };

  // User website settings
  const updateStartWeekDay = (day: WeekFirstDay) => {
    const curLocale = navigator.language.slice(0, 2);
    console.log(`Set ${day} as start day of week for locale ${curLocale}`);
    switch (day) {
      case "Sunday": {
        dayjs.updateLocale(curLocale, {
          weekStart: 0
        });
        setWeekStart(weekStart);
        break;
      }
      case "Monday": {
        dayjs.updateLocale(curLocale, {
          weekStart: 1
        });
        setWeekStart(weekStart);
        break;
      }
      default: {
        console.error(`updateStartWeekDay: Unsupported day "${day}"`);
      }
    }
  };

  const updateServingsConfiguration = async (servings: WeekServings) => {
    const requestUrl = `${API_URL}/`;

    try {
      const { request } = api.patch<Profile>(requestUrl, { servings });
      const response = await request;
      setProfile(response.data);
      enqueueSnackbar("Servings configuration saved!", { variant: "success" });
    } catch (e) {
      throw e;
    }
  };

  return (
    <ProfileContext.Provider
      value={{
        profileFieldOptions,
        loading,
        weekStart,
        profile,
        setProfile,
        saveProfile,
        updateServingsConfiguration
      }}
    >
      {children}
    </ProfileContext.Provider>
  );
};

export const useProfile = () => useContext(ProfileContext);

export default ProfileProvider;
