import { FC, SyntheticEvent, useEffect, useState } from "react";

import Box from "@mui/material/Box";
import Rating from "@mui/material/Rating";

import useRecipes from "../hooks/useRecipes";

interface RatingProps {
  recipeId: number;
  initRating: number;
}

const RecipeRating: FC<RatingProps> = ({ recipeId, initRating }) => {
  const { updateRecipeRating } = useRecipes();

  const [rating, setRating] = useState<number | null>(null);

  const onChangeHandler = async (
    event: SyntheticEvent<Element, Event>,
    value: number | null
  ) => {
    if (value !== null) {
      updateRecipeRating(recipeId, value);
    }
    setRating(value);
  };

  useEffect(() => {
    if (initRating !== undefined) {
      setRating(initRating);
    }
  }, []);
  return (
    <Box
      sx={{
        "& > legend": { mt: 2 }
      }}
    >
      <Rating
        name="simple-controlled"
        value={rating}
        onChange={onChangeHandler}
      />
    </Box>
  );
};

export default RecipeRating;
