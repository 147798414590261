import React, {
  createContext,
  FC,
  ReactNode,
  useContext,
  useEffect,
  useState
} from "react";

import {
  InCollaborator,
  InInvite,
  InInviteUpdateProperties,
  OutCollaborator,
  OutInvite
} from "../types/collaboration";
import { User } from "../types/user";
import useApi from "./ApiContext";
import { useUser } from "./UserContext";

interface UseCollaboration {
  sendCollaborationInvite: (userToEmail: string) => Promise<OutInvite>;
  getOutcomingInvitesList: () => Promise<OutInvite[]>;
  deleteOutcomingInvite: (userToEmail: string) => Promise<void>;
  getIncomingInvitesList: () => Promise<InInvite[]>;
  acceptInvite: (userFromEmail: string) => Promise<void>;
  rejectInvite: (userFromEmail: string) => Promise<void>;
  getInCollaborationsList: () => Promise<InCollaborator[]>;
  getOutCollaborationsList: () => Promise<OutCollaborator[]>;
  deleteCollaborator: (userEmail: string, incoming: boolean) => Promise<void>;
  changeActiveCollaborator: (user: User) => void;
  activeCollaboratorUser: User;
  sendingInviteError: string;
}

const CollaborationContext = createContext<UseCollaboration | undefined>(
  undefined
);

export const CollaborationProvider: FC<{ children: ReactNode }> = ({
  children
}) => {
  const API_URL = "/v1/collaborators";
  const api = useApi();
  const { user, isAuthenticated } = useUser();

  const [sendingInviteError, setSendingInviteError] = useState<string>("");
  const [activeCollaboratorUser, setActiveCollaboratorUser] =
    useState<User | null>(null);

  useEffect(() => {
    if (!isAuthenticated) {
      setActiveCollaboratorUser(null);
    }
  }, [isAuthenticated]);

  const sendCollaborationInvite = async (
    userToEmail: string
  ): Promise<OutInvite> => {
    const requestUrl = `${API_URL}/invites/out/`;

    setSendingInviteError("");
    try {
      const { request } = api.post<OutInvite>(requestUrl, {
        user_to_email: userToEmail
      });
      const response = await request;
      return response.data;
    } catch (e: any) {
      if (e.response) {
        console.info(e.response.data);
        setSendingInviteError(
          e.response.data.non_field_errors ?? "Unknown error :("
        );
        return Promise.resolve({} as OutInvite);
      } else {
        throw e;
      }
    }
  };

  const getOutcomingInvitesList = async (): Promise<OutInvite[]> => {
    const requestUrl = `${API_URL}/invites/out/`;
    const { request } = api.get<OutInvite[]>(requestUrl);
    try {
      const response = await request;
      return response.data;
    } catch (e) {
      return Promise.resolve([] as OutInvite[]);
    }
  };

  const deleteOutcomingInvite = async (userToEmail: string) => {
    const requestUrl = `${API_URL}/invites/out/${userToEmail}/`;
    const { request } = api.delete(requestUrl);
    try {
      await request;
    } catch (e) {
      throw e;
    }
  };

  const getIncomingInvitesList = async (): Promise<InInvite[]> => {
    const requestUrl = `${API_URL}/invites/in/`;
    const { request } = api.get<InInvite[]>(requestUrl);
    try {
      const response = await request;
      return response.data;
    } catch (e) {
      return Promise.resolve([] as InInvite[]);
    }
  };

  const acceptInvite = async (userFromEmail: string) => {
    await _updateIncomingInvite(userFromEmail, { accepted: true });
  };

  const rejectInvite = async (userFromEmail: string) => {
    await _updateIncomingInvite(userFromEmail, { accepted: false });
  };

  const _updateIncomingInvite = async (
    userFromEmail: string,
    properties: InInviteUpdateProperties
  ) => {
    const requestUrl = `${API_URL}/invites/in/${userFromEmail}/`;
    try {
      const { request } = api.patch(requestUrl, properties);
      await request;
    } catch (e) {
      throw e;
    }
  };

  const getInCollaborationsList = async (): Promise<InCollaborator[]> => {
    const requestUrl = `${API_URL}/in/`;
    const { request } = api.get<InCollaborator[]>(requestUrl);
    try {
      const response = await request;
      return response.data;
    } catch (e) {
      return Promise.resolve([] as InCollaborator[]);
    }
  };

  const getOutCollaborationsList = async (): Promise<OutCollaborator[]> => {
    const requestUrl = `${API_URL}/out/`;
    const { request } = api.get<OutCollaborator[]>(requestUrl);
    try {
      const response = await request;
      const collaborators = response.data;
      return collaborators;
    } catch (e) {
      return Promise.resolve([] as OutCollaborator[]);
    }
  };

  const deleteCollaborator = async (userEmail: string, incoming: boolean) => {
    const requestUrl = `${API_URL}/${incoming ? "in" : "out"}/${userEmail}/`;
    const { request } = api.delete(requestUrl);
    try {
      await request;
    } catch (e) {
      throw e;
    }
  };

  const changeActiveCollaborator = (user: User) => {
    setActiveCollaboratorUser(user);
  };

  return (
    <CollaborationContext.Provider
      value={{
        sendCollaborationInvite,
        getOutcomingInvitesList,
        deleteOutcomingInvite,
        getIncomingInvitesList,
        acceptInvite,
        rejectInvite,
        getInCollaborationsList,
        getOutCollaborationsList,
        deleteCollaborator,
        changeActiveCollaborator,
        activeCollaboratorUser: activeCollaboratorUser ?? user,
        sendingInviteError
      }}
    >
      {children}
    </CollaborationContext.Provider>
  );
};

export const useCollaboration = (): UseCollaboration => {
  const context = useContext(CollaborationContext);
  if (!context) {
    throw new Error(
      `useCollaborationsContext must be used within a CollaborationsProvider`
    );
  }
  return context;
};

export default CollaborationProvider;
