import React, { FC, useEffect, useState } from "react";

import {
  Box, Button, Container, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Typography
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";

import LoadingAnimation from "../components/LoadingAnimation";
import { useProfile } from "../contexts/ProfileContext";
import {
  ApiFields, FieldChoicesOptions, FieldOptions, FieldType, FieldValues, Value
} from "../types/api";

interface DynamicFormProps {
  fieldOptions: ApiFields;
  fieldValues: FieldValues;
  onChange: (field: string, value: Value) => void;
}

const SettingsPage = () => {
  const { profileFieldOptions, profile, setProfile, saveProfile, loading } =
    useProfile();

  const [profileFormFields, setProfileFormFields] = useState<FieldValues>({});

  const fields = ["week_start_day", "color_scheme"];

  useEffect(() => {
    if (profile === null) {
      return;
    }
    const profileFormFields: FieldValues = {};
    Object.entries(profile).map(([key, value]) => {
      if (fields.includes(key)) {
        profileFormFields[key] = value;
      }
    });
    setProfileFormFields(profileFormFields);
  }, [profile]);

  if (
    loading ||
    Object.keys(profileFieldOptions).length === 0 ||
    profile === null
  ) {
    return <LoadingAnimation />;
  }

  const onChange = (field: string, value: Value) => {
    setProfile({ ...profile, [field]: value });
  };

  return (
    <Container>
      <Box sx={{ mt: 4, mb: 4 }}>
        <Typography variant="h4">Settings</Typography>
      </Box>

      <Box>
        {Object.keys(profileFormFields).length > 0 && (
          <>
            <DynamicForm
              fieldOptions={profileFieldOptions}
              fieldValues={profileFormFields}
              onChange={onChange}
            />

            <Button
              variant="contained"
              color="success"
              onClick={saveProfile}
              sx={{ marginTop: 2 }}
            >
              Save
            </Button>
          </>
        )}
      </Box>
    </Container>
  );
};

const DynamicForm: FC<DynamicFormProps> = ({
  fieldOptions,
  fieldValues,
  onChange
}) => {
  const generateField = (fieldKey: string, options: FieldOptions) => {
    // TODO: add more field types as needed!
    switch (options.type) {
      case FieldType.CHOICE:
        return (
          <Select
            value={fieldValues[fieldKey]}
            label={options.label}
            onChange={(e: SelectChangeEvent<string | number>) => {
              onChange(fieldKey, e.target.value);
            }}
            sx={{ width: "200px" }}
          >
            {options.choices?.map((item: FieldChoicesOptions) => (
              <MenuItem key={item.value} value={item.value}>
                {item.display_name}
              </MenuItem>
            ))}
          </Select>
        );
      default:
        <Typography>
          Field type {options.type} is not handled! Fix it!
        </Typography>;
    }
  };

  return (
    <Grid container spacing={2}>
      {Object.entries(fieldOptions).map(([fieldKey, options]) => {
        return (
          <Grid
            key={fieldKey}
            xs={12}
            display="flex"
            justifyContent="flex-start"
            paddingY={3}
          >
            <FormControl>
              <InputLabel>{options.label}</InputLabel>
              {generateField(fieldKey, options)}
            </FormControl>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default SettingsPage;
