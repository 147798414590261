// src/components/RecipeForm.tsx

import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  TextField,
  Typography
} from "@mui/material";
import { styled } from "@mui/material/styles";

import { ApiResponeError } from "../types/api";
import { Recipe, RecipeImage, RecipeIngredient } from "../types/recipe";
import DayServings from "./DayServings";
import ImageUploader from "./ImageUploader";
import IngredientsForm from "./IngredientsForm";
import TextEditor from "./TextEditor";

interface RecipeFormProps {
  title: string;
  onSubmit: (recipe: Recipe) => Promise<Recipe>;
  initialValues?: Recipe | null;
  errors?: ApiResponeError;
}

const ButtonsContainer = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center"
});

const RecipeForm: FC<RecipeFormProps> = ({
  title,
  onSubmit,
  initialValues,
  errors
}) => {
  const navigate = useNavigate();
  const [recipe, setRecipe] = useState<Recipe>({} as Recipe);

  const ingredientsFormValid = useRef<boolean>(true);

  const [formValid, setFormValid] = useState<boolean>(true);

  useEffect(() => {
    if (initialValues) {
      setRecipe(initialValues);
    }
  }, []);

  useEffect(() => {
    if (
      recipe.title &&
      recipe.title.length > 0 &&
      ingredientsFormValid.current
    ) {
      setFormValid(true);
    } else {
      setFormValid(false);
    }
  }, [recipe]);

  const handleSave = async (event: React.FormEvent) => {
    // Call the API or perform other actions with the form data
    event.preventDefault();
    const newRecipe = await onSubmit(recipe);
    if (newRecipe.id !== undefined) {
      navigate(`/cook-book/${newRecipe.id}`, { replace: true });
    }
  };

  const updateRecipeField = useCallback(
    (
      key: keyof Recipe,
      value: string | number | RecipeIngredient[] | RecipeImage[]
    ) => {
      setRecipe({ ...recipe, [key]: value });
    },
    [recipe]
  );

  return (
    <Box>
      <ButtonsContainer>
        <IconButton
          onClick={() => {
            navigate(recipe?.id ? `/cook-book/${recipe.id}` : "/cook-book/");
          }}
          edge="start"
          color="inherit"
        >
          <ArrowBackIcon />
        </IconButton>
      </ButtonsContainer>
      <Typography variant="h4" mb={2}>
        {title}
      </Typography>
      <form onSubmit={handleSave}>
        <Box mb={2}>
          <FormControl fullWidth error={!!errors?.title} margin="normal">
            <TextField
              label="Title"
              fullWidth
              value={recipe.title || ""}
              onChange={(e) => updateRecipeField("title", e.target.value)}
              error={!!errors?.title}
              helperText={errors?.title?.join(", ")}
            />
          </FormControl>
        </Box>
        <Box mb={2}>
          <FormControl fullWidth error={!!errors?.details} margin="normal">
            <TextEditor
              value={recipe.details || ""}
              handleChange={(value) => updateRecipeField("details", value)}
            />
          </FormControl>
        </Box>
        <Box mb={2}>
          <ImageUploader uploadedImages={recipe.images} setRecipe={setRecipe} />
        </Box>
        <Box mb={2}>
          <FormControl error={!!errors?.servings} margin="normal">
            <DayServings
              value={recipe.servings ?? 0}
              onChange={(value: number) => updateRecipeField("servings", value)}
            />
          </FormControl>
        </Box>
        <Box mb={2}>
          <IngredientsForm
            initialIngredients={recipe.ingredients}
            updateRecipeIngredients={(ingredients: RecipeIngredient[]) =>
              updateRecipeField("ingredients", ingredients)
            }
            ingredientsFormValid={ingredientsFormValid}
            errors={(errors?.ingredients ?? []) as ApiResponeError[]}
          />
        </Box>
        <Box>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={!formValid}
          >
            Save
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default RecipeForm;
