import * as React from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";

import { Breadcrumbs as MUIBreadcrumbs, Typography } from "@mui/material";

const Breadcrumbs: React.FC = (props) => {
  const location = useLocation();

  let currentLink = "";
  const crumbs = location.pathname.split("/").filter((crumb) => crumb !== "");

  const getCrumbReadableName = (crumb: string): string =>
    crumb
      .split("-")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");

  return (
    <div role="presentation">
      <MUIBreadcrumbs aria-label="breadcrumb">
        {crumbs.length > 1 &&
          crumbs.map((crumb) => {
            currentLink = `${currentLink}/${crumb}`;
            return (
              <RouterLink
                style={{ textDecoration: "none" }}
                key={currentLink}
                to={currentLink}
              >
                <Typography
                  color="text.secondary"
                  sx={{
                    "&:hover": { textDecoration: "underline" }
                  }}
                >
                  {getCrumbReadableName(crumb)}
                </Typography>
              </RouterLink>
            );
          })}
      </MUIBreadcrumbs>
    </div>
  );
};
export default Breadcrumbs;
