import { ForwardedRef, forwardRef } from "react";
import { ConnectDragSource, useDrag } from "react-dnd";
import { Link } from "react-router-dom";

import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  SxProps,
  Typography
} from "@mui/material";

import { ItemTypes } from "../types/DnD";
import { Recipe } from "../types/recipe";

export interface RecipeCardProps {
  recipe: Recipe;
  publicRecipe?: boolean;
  drag?: ConnectDragSource;
  sx?: SxProps;
}

interface DraggableRecipeCardProps extends RecipeCardProps {
  itemType: ItemTypes;
}

export type CardRef = HTMLDivElement;

export const RecipeCard = forwardRef<CardRef | null, RecipeCardProps>(
  (
    { recipe, drag, publicRecipe, sx },
    forwardedRef?: ForwardedRef<CardRef | null>
  ) => {
    const imageUrl = recipe.images[0]?.file || "/images/fallback_dall_e.png";
    const linkUrl = publicRecipe
      ? `/cook-book/public/${recipe.id}`
      : `/cook-book/${recipe.id}`;
    return (
      <Card
        ref={(el) => {
          if (forwardedRef) {
            typeof forwardedRef === "function"
              ? forwardedRef(el)
              : (forwardedRef.current = el);
          }
          drag && drag(el);
        }}
        sx={{ m: 2, ...sx, maxWidth: 350 }}
      >
        <CardActionArea component={Link} to={linkUrl}>
          <CardMedia
            component="img"
            sx={{
              width: "100%"
            }}
            image={imageUrl}
            alt={recipe.title}
          />
          <CardContent>
            <Typography variant="subtitle1" color="text.secondary" noWrap>
              {recipe.title}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    );
  }
);

export const DraggableRecipeCard = forwardRef<
  CardRef | null,
  DraggableRecipeCardProps
>(({ recipe, itemType, publicRecipe }, forwardedRef?) => {
  const [{ opacity }, drag] = useDrag(
    () => ({
      type: itemType,
      item: recipe,
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0.1 : 1
      })
    }),
    [recipe]
  );

  return (
    <RecipeCard
      ref={(el) => {
        if (forwardedRef) {
          typeof forwardedRef === "function"
            ? forwardedRef(el)
            : (forwardedRef.current = el);
        }
        drag && drag(el);
      }}
      recipe={recipe}
      drag={drag}
      sx={{ opacity }}
      publicRecipe={publicRecipe}
    />
  );
});
