import { Dayjs } from "dayjs";
import { FC } from "react";
import { generatePath, Link as RouterLink } from "react-router-dom";

import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Link,
  Typography
} from "@mui/material";

import { WeekDayMenu } from "../types/menu";
import { User } from "../types/user";

interface DayRecipesProps {
  activeCollaboratorUser: User;
  year: number;
  week: number;
  date: Dayjs;
  recipes: WeekDayMenu[];
}

const getDayCardTitle = (date: Dayjs) => {
  return `${date.format("dddd")} [${date.format("l")}]`;
};

const DayRecipes: FC<DayRecipesProps> = ({
  year,
  week,
  activeCollaboratorUser,
  date,
  recipes
}) => {
  return (
    <Card>
      <CardHeader
        title={
          <Typography variant="subtitle1" fontWeight="bold">
            {getDayCardTitle(date)}
          </Typography>
        }
        sx={{
          backgroundColor: "secondary.main",
          borderBottom: "1px solid rgba(0, 0, 0, 0.15)", // Bottom border
          padding: "0.2rem 1rem", // Padding adjustment
          color: "#fff4f4"
        }}
      />
      <CardContent>
        {recipes.map((recipe) => (
          <Grid
            key={recipe.recipe}
            container
            alignItems="center"
            sx={{ marginBottom: 2, paddingLeft: "8px" }}
          >
            <Grid item xs={12}>
              <Link
                underline="hover"
                component={RouterLink}
                to={generatePath(
                  "/menu/:username/:year/:week/:day/recipe/:recipeId",
                  {
                    username: activeCollaboratorUser.username,
                    year: year.toString(),
                    week: week.toString(),
                    day: date.day().toString(),
                    recipeId: recipe.recipe.toString()
                  }
                )}
              >
                <Typography variant="h6">{recipe.title}</Typography>
              </Link>
            </Grid>
          </Grid>
        ))}
      </CardContent>
    </Card>
  );
};

export default DayRecipes;
