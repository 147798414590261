import axios, { AxiosRequestConfig } from "axios";

export const BASE_URL = `${window.location.port === "" ? window.location.origin : "http://127.0.0.1:8000"}/api`;

class Api {
  private apiInstance = axios.create({
    baseURL: BASE_URL,
    headers: {
      'Content-Type': 'application/json'
    }
  });

  setToken = (token: string) => {
    this.apiInstance.defaults.headers.Authorization = `Token ${token}`;
  }

  clearToken = () => {
    delete this.apiInstance.defaults.headers["Authorization"];
  }

  get = <T>(url: string, config?: AxiosRequestConfig) => {
    const abortController = new AbortController();
    config = { ...config, signal: abortController.signal };
    return {
      request: this.apiInstance.get<T>(url, config),
      abortController
    };
  }

  post = <T>(url: string, data?: any, config?: AxiosRequestConfig) => {
    const abortController = new AbortController();
    config = { ...config, signal: abortController.signal };
    return {
      request: this.apiInstance.post<T>(url, data, config),
      abortController
    };
  }

  put = <T>(url: string, data?: any, config?: AxiosRequestConfig) => {
    const abortController = new AbortController();
    config = { ...config, signal: abortController.signal };
    return {
      request: this.apiInstance.put<T>(url, data, config),
      abortController
    };
  }

  delete = <T>(url: string, config?: AxiosRequestConfig) => {
    const abortController = new AbortController();
    config = { ...config, signal: abortController.signal };
    return {
      request: this.apiInstance.delete<T>(url, config),
      abortController
    };
  }

  patch = <T>(url: string, data?: any, config?: AxiosRequestConfig) => {
    const abortController = new AbortController();
    config = { ...config, signal: abortController.signal };
    return {
      request: this.apiInstance.patch<T>(url, data, config),
      abortController
    };
  }

  options = <T>(url: string, config?: AxiosRequestConfig) => {
    const abortController = new AbortController();
    config = { ...config, signal: abortController.signal };
    return {
      request: this.apiInstance.options<T>(url, config),
      abortController
    };
  }
}

export default Api;
