import dayjs, { Dayjs } from "dayjs";
import isoWeek from "dayjs/plugin/isoWeek";
import weekday from "dayjs/plugin/weekday";
import { useEffect, useState } from "react";
import {
  generatePath,
  Navigate,
  Outlet,
  useNavigate,
  useParams
} from "react-router-dom";

import { Button } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";

import { useCollaboration } from "../contexts/CollaborationContext";
import { useUser } from "../contexts/UserContext";
import useDateUtils from "../hooks/useDateUtils";
import useMenus from "../hooks/useMenus";
import { WeekDayMenuMap } from "../types/menu";
import { DatepickerBar } from "./DatepickerBar";
import LoadingAnimation from "./LoadingAnimation";

dayjs.extend(isoWeek);
dayjs.extend(weekday);

export interface WeeklyMenuOutletProps {
  year: number;
  week: number;
  weekMenu: WeekDayMenuMap;
  setWeekMenu: React.Dispatch<React.SetStateAction<WeekDayMenuMap>>;
  weekDates: Dayjs[];
  setDateBarButtonText: React.Dispatch<React.SetStateAction<string>>;
  setDateBarButtonLink: React.Dispatch<React.SetStateAction<string>>;
  setDateBarUrlSuffix: React.Dispatch<React.SetStateAction<string>>;
}

interface LayoutMenuParams {
  username: string;
  year?: string;
  week?: string;
}

export const generateMenuUrl = (
  username: string,
  year?: string | undefined,
  week?: string | undefined,
  urlSuffix?: string | undefined
) => {
  return generatePath("/menu/:username/:year/:week/:urlSuffix", {
    username,
    year: year ?? "",
    week: week ?? "",
    urlSuffix: urlSuffix ?? ""
  });
};

const LayoutMenu = () => {
  const navigate = useNavigate();
  const { user } = useUser();
  const { activeCollaboratorUser } = useCollaboration();
  const { username, year, week } = useParams<keyof LayoutMenuParams>();

  const { getCurrentDate, getYear, getWeekNumber, getWeekDates } =
    useDateUtils();

  const { getWeekMenu } = useMenus(activeCollaboratorUser.id);

  const today = getCurrentDate();
  const [weekNum, setWeekNum] = useState<number>(getWeekNumber(today));
  const [yearNum, setYearNum] = useState<number>(getYear(today));
  const [weekDates, setWeekDates] = useState<Dayjs[]>([]);
  const [weekMenu, setWeekMenu] = useState<WeekDayMenuMap>({});
  const [dateBarButtonText, setDateBarButtonText] = useState<string>("");
  const [dateBarButtonLink, setDateBarButtonLink] = useState<string>("");
  const [dateBarUrlSuffix, setDateBarUrlSuffix] = useState<string>("");

  useEffect(() => {
    const fetchMenu = async (year: number, week: number) => {
      const menu = await getWeekMenu(year, week);
      setWeekMenu(menu);
    };

    let newYearNum = yearNum;
    let newWeekNum = weekNum;
    if (year !== undefined && week !== undefined) {
      newYearNum = parseInt(year);
      newWeekNum = parseInt(week);
      setYearNum(newYearNum);
      setWeekNum(newWeekNum);
    }
    fetchMenu(newYearNum, newWeekNum);
    setWeekDates(getWeekDates(newYearNum, newWeekNum));
  }, [year, week, activeCollaboratorUser]);

  if (username === undefined) {
    if (activeCollaboratorUser !== user) {
      return (
        <Navigate
          to={generateMenuUrl(activeCollaboratorUser.username)}
          replace={true}
        />
      );
    }
  }

  if (weekDates.length === 0) {
    return <LoadingAnimation />;
  }

  return (
    <Grid container>
      <Grid xs={12}>
        <DatepickerBar weekDates={weekDates} urlSuffix={dateBarUrlSuffix}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              navigate(dateBarButtonLink);
            }}
          >
            {dateBarButtonText}
          </Button>
        </DatepickerBar>
      </Grid>
      <Grid id="fff" xs={12} container spacing={2} paddingTop="18px">
        <Outlet
          context={{
            year: yearNum,
            week: weekNum,
            weekMenu,
            setWeekMenu,
            weekDates,
            setDateBarButtonText,
            setDateBarButtonLink,
            setDateBarUrlSuffix
          }}
        />
      </Grid>
    </Grid>
  );
};

export default LayoutMenu;
