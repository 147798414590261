import React, { FC, useState } from "react";
import { useNavigate } from "react-router-dom";

import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Link,
  Typography
} from "@mui/material";
import { styled } from "@mui/material/styles";

import { useUnits } from "../contexts/UnitsContext";
import useMathFunctions from "../hooks/useMathFunctions";
import useRecipes from "../hooks/useRecipes";
import { Fraction, Recipe } from "../types/recipe";
import RecipeRating from "./RacipeRating";

const ImageContainer = styled(Box)(({ theme }) => ({
  marginRight: theme.spacing(2)
}));

const RecipeContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2)
}));

const IngredientList = styled("ul")(({ theme }) => ({
  listStyleType: "none",
  paddingLeft: 0
}));

const IngredientListItem = styled("li")(({ theme }) => ({
  marginBottom: theme.spacing(1)
}));

const ButtonsContainer = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center"
});

interface RecipeDetailsProps {
  recipe: Recipe;
  onDelete?: (recipeId: number) => void;
  publicRecipe?: boolean;
  servings?: number;
}

interface RecipeImageProps {
  src: string;
}

const placeholderImage = "https://via.placeholder.com/300";

const RecipeDetails: FC<RecipeDetailsProps> = ({
  recipe,
  onDelete,
  publicRecipe,
  servings
}) => {
  const navigate = useNavigate();

  const { fractionToString } = useMathFunctions();
  const { units } = useUnits();

  const [delConfirmOpen, setDelConfirmOpen] = useState<boolean>(false);
  const [savedRecipe, setSavedRecipe] = useState<Recipe | null>(null);

  const handleBackButtonClick = () => {
    navigate("/cook-book");
  };

  const handleDeleteButtonClick = async (recipeId: string | number | null) => {
    if (!onDelete) return;

    setDelConfirmOpen(false);
    if (!!recipeId) {
      onDelete(recipeId as number);
      navigate("/cook-book");
    }
  };
  const { createRecipe } = useRecipes();

  const callCreateRecipe = async (recipe: Recipe) => {
    let newRecipe = await createRecipe(recipe);
    setSavedRecipe(newRecipe);
  };

  const getIngredientAdjustedAmount = (fraction: Fraction | undefined) => {
    if (fraction === undefined) return "";
    return fractionToString(fraction);
    // if (!servings || servings === 0 || recipe.servings === 0) return amount;
    // const forOneServing = amount / recipe.servings;
    // console.log(forOneServing);
    // return Math.ceil(forOneServing * servings);
  };

  return (
    <RecipeContainer>
      <ButtonsContainer>
        <IconButton
          onClick={handleBackButtonClick}
          edge="start"
          color="inherit"
        >
          <ArrowBackIcon />
        </IconButton>

        {publicRecipe ? (
          <Box>
            {savedRecipe ? (
              <Link
                aria-labelledby="link to the recipe"
                href={`/cook-book/${savedRecipe.id}/`}
              >
                The recipe saved to your cook book
              </Link>
            ) : (
              <Button
                onClick={() => callCreateRecipe(recipe)}
                variant="outlined"
                startIcon={<AddIcon />}
              >
                Save to your cook book
              </Button>
            )}
          </Box>
        ) : (
          <Box>
            <IconButton
              onClick={() => navigate(`/cook-book/${recipe.id}/edit`)}
            >
              <EditIcon />
            </IconButton>
            {onDelete && (
              <IconButton onClick={() => setDelConfirmOpen(true)}>
                <DeleteIcon />
              </IconButton>
            )}
          </Box>
        )}
      </ButtonsContainer>

      <Typography variant="h4" align="center" gutterBottom>
        {recipe.title}
      </Typography>
      <Box style={{ display: "flex", alignItems: "center" }}>
        <ImageContainer>
          <RecipeImage
            src={
              recipe.images.length > 0
                ? recipe.images[0].file
                : placeholderImage
            }
          />
        </ImageContainer>
        <Box>
          <RecipeRating
            recipeId={recipe.id as number}
            initRating={recipe.rating}
          />
          <Typography
            variant="body1"
            dangerouslySetInnerHTML={{ __html: recipe.details as string }}
          />
          <Typography variant="body1">
            Servings:{" "}
            {recipe.servings === 0 ? "Not set" : servings ?? recipe.servings}
          </Typography>
          <Typography variant="h6">Ingredients:</Typography>
          <IngredientList>
            {recipe.ingredients.map((recipeIngredient) => (
              <IngredientListItem key={recipeIngredient.ingredient.id}>
                {recipeIngredient.ingredient.name} -{" "}
                {getIngredientAdjustedAmount(recipeIngredient.fraction)}{" "}
                {
                  units?.find((unit) => unit.id === recipeIngredient.units)
                    ?.name
                }
              </IngredientListItem>
            ))}
          </IngredientList>
        </Box>
      </Box>

      <Dialog
        open={delConfirmOpen}
        onClose={() => {
          setDelConfirmOpen(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Delete recipe?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete "{recipe.title}"? This action can't
            be reverted.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setDelConfirmOpen(false);
            }}
          >
            Disagree
          </Button>
          <Button onClick={() => handleDeleteButtonClick(recipe?.id)} autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </RecipeContainer>
  );
};

export const RecipeImage: FC<RecipeImageProps> = ({ src }) => {
  // TODO add loading bar or anything else related to the image here

  return <img src={src} alt="Preview" style={{ maxWidth: "400px" }} />;
};

export default RecipeDetails;
