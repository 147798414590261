import axios from "axios";
import React, { useState } from "react";

import useApi from "../contexts/ApiContext";
import { PaginatedRecipes, Recipe, Unit } from "../types/recipe";

interface UseRecipes {
  errors: RequestErrors;
  loading: boolean;
  getMyRecipes: (
    searchTitle: string,
    offset: number,
    limit?: number,
    sortField?: string
  ) => Promise<PaginatedRecipes>;
  getPublicRecipes: (
    searchTitle: string,
    offset: number,
    limit?: number,
    sortField?: string
  ) => Promise<PaginatedRecipes>;
  getRecipeById: (
    id: number | string,
    publicRecipe: boolean
  ) => Promise<Recipe>;
  deleteRecipeById: (id: number | string) => Promise<void>;
  createRecipe: (recipe: Recipe) => Promise<Recipe>;
  updateRecipe: (recipe: Recipe) => Promise<Recipe>;
  updateRecipeRating: (recipeId: number, rating: number) => Promise<void>;
  savePublicRecipe: (recipeId: number) => Promise<Recipe>;
  getUnits: () => Promise<Unit[]>;
}

type RequestErrors = {
  [field: string]: string;
};

export const useRecipes = (): UseRecipes => {
  const API_URL = "/v1/recipes";
  const api = useApi();

  const [errors, setErrors] = useState<RequestErrors>({});
  const [loading, setLoading] = useState<boolean>(false);

  const _getRecipes = async (requestUrl: string): Promise<PaginatedRecipes> => {
    setLoading(true);
    try {
      const { request } = api.get<PaginatedRecipes>(requestUrl);
      const response = await request;
      setErrors({});
      return response.data;
    } catch (error) {
      let errorMessage = "{form: 'Error occured'}";
      if (error instanceof Error) {
        errorMessage = error.message;
      }
      setErrors({ ...errors, ...JSON.parse(errorMessage) });
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const getMyRecipes = async (
    searchTitle: string,
    offset: number,
    limit?: number,
    sortField?: string
  ): Promise<PaginatedRecipes> => {
    const applyLimit = limit || 30;
    let requestUrl = `${API_URL}/my/?search=${searchTitle}&limit=${applyLimit}&offset=${offset}`;
    requestUrl =
      sortField === undefined
        ? requestUrl
        : `${requestUrl}&ordering=${sortField}`;
    return _getRecipes(requestUrl);
  };

  const getPublicRecipes = async (
    searchTitle: string,
    offset: number,
    limit?: number,
    sortField?: string
  ): Promise<PaginatedRecipes> => {
    const applyLimit = limit || 30;
    let requestUrl = `${API_URL}/public/?search=${searchTitle}&limit=${applyLimit}&offset=${offset}`;
    requestUrl =
      sortField === undefined
        ? requestUrl
        : `${requestUrl}&ordering=${sortField}`;
    return _getRecipes(requestUrl);
  };

  const getRecipeById = async (
    id: number | string,
    publicRecipe: boolean
  ): Promise<Recipe> => {
    setLoading(true);
    const requestUrl = publicRecipe
      ? `v1/recipes/public/${id}`
      : `v1/recipes/my/${id}`;

    try {
      const { request } = api.get<Recipe>(requestUrl);
      const response = await request;
      setErrors({});
      return response.data;
    } catch (error) {
      let errorMessage = "{form: 'Error occured'}";
      if (error instanceof Error) {
        errorMessage = error.message;
      }
      setErrors({ ...errors, ...JSON.parse(errorMessage) });
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const deleteRecipeById = async (id: string | number) => {
    setLoading(true);
    const requestUrl = `v1/recipes/my/${id}`;
    try {
      const { request } = api.delete(requestUrl);
      await request;
      setErrors({});
    } catch (error) {
      console.log(error);
      let errorMessage = "{form: 'Error occured'}";
      if (error instanceof Error) {
        errorMessage = error.message;
      }
      setErrors({ ...errors, ...JSON.parse(errorMessage) });
    } finally {
      setLoading(false);
    }
  };

  const createRecipe = async (recipe: Recipe): Promise<Recipe> => {
    setLoading(true);
    const requestUrl = `${API_URL}/my/`;
    try {
      const { request } = api.post<Recipe>(requestUrl, recipe);
      const response = await request;
      return response.data;
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const updateRecipe = async (recipe: Recipe) => {
    setLoading(true);
    const requestUrl = `v1/recipes/my/${recipe.id}`;
    try {
      const { request } = api.put<Recipe>(requestUrl, recipe);
      const response = await request;
      return response.data;
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const updateRecipeRating = async (
    recipeId: number,
    rating: number
  ): Promise<void> => {
    const requestUrl = `${API_URL}/my/${recipeId}`;
    setErrors({});
    try {
      const { request } = api.patch<Recipe>(requestUrl, { rating });
      await request;
    } catch (error) {
      let errorMessage = "{form: 'Error occured'}";
      if (error instanceof Error) {
        errorMessage = error.message;
      }
      setErrors({ ...errors, ...JSON.parse(errorMessage) });
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const savePublicRecipe = async (recipeId: number): Promise<Recipe> => {
    setLoading(true);
    const requestUrl = `v1/recipes/my/saved/`;
    try {
      const { request } = api.post<Recipe>(requestUrl, {
        public_recipe: recipeId
      });
      setErrors({});
      const response = await request;
      return response.data; // return UserRecipe created by cloning the public one
    } catch (error) {
      if (error instanceof Error) {
        setErrors({ ...errors, ...JSON.parse(error.message) });
      }
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const getUnits = async () => {
    setLoading(true);
    const requestUrl = `${API_URL}/units/`;
    try {
      const { request } = api.get<Unit[]>(requestUrl);
      const response = await request;
      setErrors({});

      return response.data; // to fill the form in case of errors
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("Get units request was cancelled");
        return [] as Unit[];
      }
      let errorMessage = "{form: 'Error occured'}";
      if (error instanceof Error) {
        errorMessage = error.message;
      }
      setErrors({ ...errors, ...JSON.parse(errorMessage) });
      throw error;
    } finally {
      setLoading(false);
    }
  };

  return {
    errors,
    loading,
    getMyRecipes,
    getPublicRecipes,
    getRecipeById,
    deleteRecipeById,
    createRecipe,
    updateRecipe,
    updateRecipeRating,
    savePublicRecipe,
    getUnits
  };
};

export default useRecipes;
