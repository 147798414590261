import React, { FC, useEffect, useState } from "react";

import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Autocomplete,
  Box,
  Button,
  createFilterOptions,
  Divider,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  TextField
} from "@mui/material";

import { useUnits } from "../contexts/UnitsContext";
import { useIngredients } from "../hooks/useIngredients";
import useMathFunctions from "../hooks/useMathFunctions";
import useRecipes from "../hooks/useRecipes";
import { ApiResponeError } from "../types/api";
import { Fraction, Ingredient, RecipeIngredient } from "../types/recipe";
import LoadingAnimation from "./LoadingAnimation";

interface ComponentProps {
  initialIngredients?: RecipeIngredient[];
  updateRecipeIngredients: (ingredients: RecipeIngredient[]) => void;
  ingredientsFormValid: React.MutableRefObject<boolean>;
  errors: ApiResponeError[];
}

const IngredientsForm: FC<ComponentProps> = ({
  initialIngredients,
  updateRecipeIngredients,
  ingredientsFormValid,
  errors
}) => {
  const [ingredients, setIngredients] = useState<RecipeIngredient[]>([]);
  const { units } = useUnits();
  const { loading } = useRecipes();
  const {
    decimalToFraction,
    stringToFraction,
    fractionToString,
    isValidDecimalOrFraction
  } = useMathFunctions();

  const [quantityValues, setQuantityValues] = useState<string[]>([]);
  const [quantityErrors, setQuantityErrors] = useState<(string | null)[]>([]);

  useEffect(() => {
    if (initialIngredients && ingredients.length === 0) {
      // we want to set the initial ingredients only first time
      setIngredients(initialIngredients);
      // set our temp array with quantity values to the initial values
      setQuantityValues(
        initialIngredients.map((ingredient) =>
          ingredient.fraction ? fractionToString(ingredient.fraction) : ""
        )
      );
    }
  }, [initialIngredients]);

  useEffect(() => {
    const newErrors = [...quantityErrors];
    if (ingredients.length > 0) {
      ingredientsFormValid.current = ingredients.every((ingredient, index) => {
        const fractionValid = isValidDecimalOrFraction(quantityValues[index]);
        const valid =
          ingredient.ingredient?.name.length > 0 &&
          fractionValid &&
          ingredient.units !== undefined;

        if (quantityValues[index] !== "" && !fractionValid) {
          newErrors[index] = "Not a valid number";
        } else {
          newErrors[index] = null;
        }
        return valid;
      });
    } else {
      ingredientsFormValid.current = true;
    }
    setQuantityErrors(newErrors);
  }, [ingredients]);

  const addIngredient = () => {
    const defaultUnits = units ? units[0].id : -1;
    setIngredients([
      ...ingredients,
      { ingredient: { name: "" }, units: defaultUnits } as RecipeIngredient
    ]);
    setQuantityValues([...quantityValues, ""]);
  };

  const removeIngredient = (index: number) => {
    const newIngredients: RecipeIngredient[] = ingredients.filter(
      (_, i) => i !== index
    );
    setIngredients(newIngredients);
    updateRecipeIngredients(newIngredients);
    setQuantityValues(quantityValues.filter((_, i) => i !== index));
  };

  const updateIngredient = (
    index: number,
    updatedIngredient: {
      fraction?: Fraction;
      units?: string;
      ingredient?: Ingredient;
    }
  ) => {
    const newIngredients = ingredients.map((ingredient, i) =>
      i === index
        ? {
            ...ingredient,
            units: ingredient.units,
            ...updatedIngredient
          }
        : ingredient
    );

    setIngredients(newIngredients);
    updateRecipeIngredients(newIngredients);
  };

  const handleQuantityChange = (newValue: string, index: number) => {
    setQuantityValues(
      quantityValues.map((v, i) => (i === index ? newValue : v))
    );
    let fraction: Fraction;
    if (newValue.includes("/")) {
      fraction = stringToFraction(newValue);
    } else {
      fraction = decimalToFraction(parseFloat(newValue));
    }
    updateIngredient(index, { fraction });
  };

  // Autocomplete variables and hooks
  const { searchIngredients, createIngredient } = useIngredients();
  const [ingredientOptions, setIngredientOptions] = useState<Ingredient[]>([]);

  const filterOptions = createFilterOptions({
    matchFrom: "start",
    stringify: (option: Ingredient) => option.name
  });

  const handleSearch = async (searchText: string) => {
    const results = await searchIngredients(searchText);
    setIngredientOptions(results);
  };

  const handleCreate = async (newValue: string | Ingredient, index: number) => {
    const name = typeof newValue === "string" ? newValue : newValue.name;
    const newIngredient = await createIngredient(name);

    updateIngredient(index, {
      ingredient: {
        id: newIngredient.id,
        name: newIngredient.name
      }
    });
  };

  if (loading) {
    return <LoadingAnimation />;
  }

  return (
    <>
      <Divider>Ingredients</Divider>
      <Box mt={2}>
        {ingredients.map((recipeIngredient, index) => (
          <Grid
            container
            spacing={{ xs: 1, md: 2 }}
            key={recipeIngredient.ingredient?.id ?? `id-${index}`}
            alignItems="center"
            mb={2}
          >
            <Grid item xs={4} md={6}>
              <FormControl
                fullWidth
                margin="normal"
                error={errors[index]?.ingredient !== undefined}
              >
                <Autocomplete
                  selectOnFocus
                  freeSolo
                  clearOnBlur
                  openOnFocus
                  handleHomeEndKeys
                  onFocus={(_) =>
                    handleSearch(ingredients[index]?.ingredient?.name ?? "")
                  } // force redo search, because it wasn't for some reason
                  value={recipeIngredient.ingredient?.name ?? ""}
                  options={ingredientOptions}
                  getOptionDisabled={(option) =>
                    // disable already selected ingredients
                    ingredients.some((ri) => ri.ingredient?.id === option.id)
                  }
                  getOptionLabel={(option) =>
                    typeof option === "string" ? option : option.name
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      autoFocus={
                        index === ingredients.length - 1 &&
                        !!!ingredients[ingredients.length - 1]?.ingredient?.name
                      }
                      label="Ingredient name"
                      error={errors[index]?.ingredient !== undefined}
                      helperText={errors[index]?.ingredient?.join(", ") ?? ""}
                    />
                  )}
                  filterOptions={(options, params) => {
                    const filtered = filterOptions(options, params);

                    if (params.inputValue !== "") {
                      filtered.push({
                        id: -1,
                        name: `Add "${params.inputValue}"`
                      });
                    }

                    return filtered;
                  }}
                  onInputChange={(_, value) => handleSearch(value)}
                  onChange={(event, newValue) => {
                    if (typeof newValue === "string") {
                      handleCreate(newValue, index);
                    } else if (newValue && newValue.id === -1) {
                      handleCreate(newValue.name.slice(5, -1), index); // cut off "Add "
                    } else {
                      if (newValue) {
                        updateIngredient(index, {
                          ingredient: { id: newValue.id, name: newValue.name }
                        });
                      }
                    }
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={3} md={2}>
              <FormControl
                fullWidth
                margin="normal"
                error={
                  errors[index]?.fraction !== undefined ||
                  !!quantityErrors[index]
                }
              >
                <TextField
                  label="Quantity"
                  variant="outlined"
                  fullWidth
                  value={quantityValues[index] ?? ""}
                  onChange={(e) => handleQuantityChange(e.target.value, index)}
                  error={
                    errors[index]?.fraction !== undefined ||
                    !!quantityErrors[index]
                  }
                  helperText={
                    errors[index]?.fraction?.join(", ") ??
                    quantityErrors[index] ??
                    ""
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={4} md={3}>
              <FormControl fullWidth margin="normal">
                <TextField
                  label="Unit"
                  select
                  variant="outlined"
                  fullWidth
                  value={recipeIngredient?.units}
                  onChange={(e) =>
                    updateIngredient(index, { units: e.target.value })
                  }
                  required
                >
                  {units?.map((unit) => (
                    <MenuItem key={unit.id} value={unit.id}>
                      {unit.name}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </Grid>
            <Grid item xs={1}>
              <IconButton onClick={() => removeIngredient(index)}>
                <DeleteIcon />
              </IconButton>
            </Grid>
          </Grid>
        ))}
        <Button
          startIcon={<AddIcon />}
          onClick={addIngredient}
          sx={{ marginTop: 2 }}
        >
          Add Ingredient
        </Button>
      </Box>
    </>
  );
};

export default IngredientsForm;
