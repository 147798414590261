import {
  Components,
  createTheme,
  PaletteColor,
  PaletteColorOptions,
  PaletteMode,
  Theme
} from "@mui/material";

import { Mode } from "../types/theme";

// const lightPrimary = "#5c6bc0";
// const darkPrimary = "#607d8b";
// const lightSecondary = "#ec407a";
// const darkSecondary = "#80cbc4";
// const lightBackgroundDefault = "#f5f5f5";
// const darkBackgroundDefault = "#121212";
// const lightBackgroundPaper = "#ffffff";
// const darkBackgroundPaper = "#1e1e1e";
// const lightTextPrimary = "#000000";
// const darkTextPrimary = "#ffffff";
// const lightTextSecondary = "#000000";
// const darkTextSecondary = "#b0bec5";

const lightPrimary = "#98bc62";
const darkPrimary = "rgb(106, 131, 68)";
const lightSecondary = "rgb(53, 74, 26)";
const darkSecondary = "#494e3b";
const lightBackgroundDefault = "#f5f5f5";
const darkBackgroundDefault = "#252725";
const lightBackgroundPaper = "#ffffff";
const darkBackgroundPaper = "#30312f";
const lightTextPrimary = "#000000";
const darkTextPrimary = "#fff";
const lightTextSecondary = "#253C2D";
const darkTextSecondary = "rgba(255, 255, 255, 0.93)";
//TODO: use setAlpha function to reduce number of vars
const backgroundColorSelected = "rgba(255, 255, 255, 0.1)";
const backgroundColorSelectedHover = "rgba(255, 255, 255, 0.15)";
//Try this:
const darkDivider = "rgba(255, 255, 255, 0.12)";
const lightDivider = "rgba(0, 0, 0, 0.12)";

declare module "@mui/material/styles" {
  interface Palette {
    customOptionalVariable?: string;
  }
  interface PaletteOptions {
    customOptionalVariable?: string;
  }
}

export const getPreferredColorScheme = () => {
  if (typeof window !== "undefined") {
    const prefersDarkMode = window.matchMedia(
      "(prefers-color-scheme: dark)"
    ).matches;
    return prefersDarkMode ? Mode.DARK : Mode.LIGHT;
  }
  return Mode.LIGHT;
};

const createMyTheme = (colorMode: PaletteMode): Theme => {
  return createTheme({
    palette: {
      mode: colorMode,
      primary: {
        main: colorMode === Mode.LIGHT ? lightPrimary : darkPrimary,
        dark: colorMode === Mode.LIGHT ? "#79944b" : "#79944b"
      },
      secondary: {
        main: colorMode === Mode.LIGHT ? lightSecondary : darkSecondary
      },
      background: {
        default:
          colorMode === Mode.LIGHT
            ? lightBackgroundDefault
            : darkBackgroundDefault,
        paper:
          colorMode === Mode.LIGHT ? lightBackgroundPaper : darkBackgroundPaper
      },
      text: {
        primary: colorMode === Mode.LIGHT ? lightTextPrimary : darkTextPrimary,
        secondary:
          colorMode === Mode.LIGHT ? lightTextSecondary : darkTextSecondary
      },
      divider: colorMode === Mode.LIGHT ? lightDivider : darkDivider,
      grey: { A100: "#d0cbc3" }
    },
    typography: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontSize: 14,
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightMedium: 500,
      fontWeightBold: 700
    },
    spacing: (factor: number) => `${0.25 * factor}rem`,
    components: {
      MuiGrid: {
        styleOverrides: {
          root: {
            backgroundColor:
              colorMode === Mode.LIGHT
                ? lightBackgroundDefault
                : darkBackgroundDefault
          }
        }
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            "&.Mui-selected": {
              backgroundColor: backgroundColorSelected,
              "&:hover": {
                backgroundColor: backgroundColorSelectedHover
              }
            }
          }
        }
      },

      ...({
        // a trick for DatePicker
        //TODO: check if it is not broken by new color vars
        MuiDayCalendar: {
          styleOverrides: {
            weekContainer: {
              "&:hover": {
                backgroundColor:
                  colorMode === Mode.LIGHT ? lightPrimary : darkPrimary
              },
              "& button:hover": {
                backgroundColor:
                  colorMode === Mode.LIGHT ? lightSecondary : darkSecondary
              }
            }
          }
        }
        // MaterialDesignContent: {
        //   styleOverrides: {
        //     "&.notistack-MuiContent-success": {
        //       backgroundColor: "#2D7738"
        //     }
        //   }
        // }
      } as Partial<Components>)
    }
  });
};

export default createMyTheme;
