import React from "react";

import { Box, Link, Typography } from "@mui/material";

const ConstructionImage = () => {
  return (
    <Box textAlign="center">
      <Typography variant="h4">🚧</Typography>
    </Box>
  );
};

const NotFoundPage = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      minHeight="100vh"
    >
      <ConstructionImage />
      <Typography variant="h5">Oops! Page not found.</Typography>
      <Typography mt={1}>
        We couldn't find what you were looking for. Here are some helpful links
        instead:
      </Typography>
      <Box mt={2}>
        <Link href="/" variant="body1">
          Home
        </Link>
        <Link href="/cook-book" variant="body1" ml={2}>
          Cook Book
        </Link>
        {/* Add more helpful links here */}
      </Box>
    </Box>
  );
};

export default NotFoundPage;
