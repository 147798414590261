import React, { createContext, useContext, useReducer } from "react";

import { User } from "../types/user";
import useApi from "./ApiContext";

type State = {
  isAuthenticated: boolean;
  user: User;
};

type Action =
  | { type: "LOGIN"; payload: User }
  | { type: "REGISTER"; payload: {} }
  | { type: "LOGOUT" };

type UserDispatch = (action: Action) => void;

const UserStateContext = createContext<State | undefined>(undefined);
const UserDispatchContext = createContext<UserDispatch | undefined>(undefined);

function userReducer(state: State, action: Action): State {
  switch (action.type) {
    case "LOGIN":
      localStorage.setItem("user", JSON.stringify(action.payload));
      return { ...state, isAuthenticated: true, user: action.payload };
    case "REGISTER":
    case "LOGOUT":
      localStorage.clear();
      return { ...state, isAuthenticated: false };
    default:
      throw new Error(`Unhandled action type`);
  }
}

interface UserProviderProps {
  children: React.ReactNode;
}

export const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
  const api = useApi();

  const initialState: State = {
    isAuthenticated: false,
    user: {} as User
  };
  // Initialize the state from localStorage
  const storedUser = localStorage.getItem("user");
  if (storedUser) {
    const user = JSON.parse(storedUser);
    initialState.isAuthenticated = true;
    initialState.user = user;
  }

  const [state, dispatch] = useReducer(userReducer, initialState);
  // Set or clear a token for requests according to the user status (logged in or out):
  if (state.isAuthenticated) {
    api.setToken(state.user.token ?? "");
  } else {
    api.clearToken();
  }

  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>
        {children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
};

export function useUser(): State {
  const context = useContext(UserStateContext);

  if (context === undefined) {
    throw new Error("useUser must be used within a UserProvider");
  }

  return context;
}

export function useUserDispatch(): UserDispatch {
  const context = useContext(UserDispatchContext);

  if (context === undefined) {
    throw new Error("useUserDispatch must be used within a UserProvider");
  }

  return context;
}
