import React, { useState } from "react";
import { Link, Navigate } from "react-router-dom";

import LoadingButton from "@mui/lab/LoadingButton";
import { FormControl, FormHelperText, Grid, Paper, TextField, Typography } from "@mui/material";

import { useUser } from "../contexts/UserContext";
import { useAuth } from "../hooks/useAuth";

const Login: React.FC = () => {
  const { login, error } = useAuth();
  const { isAuthenticated } = useUser();

  const [loading, setLoading] = useState<boolean>(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [authError, setAuthError] = useState("");

  // Redirect to the index page if isAuthenticated is true
  if (isAuthenticated) {
    return <Navigate to="/" replace={true} />;
  }

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    setEmailError(false);
    setPasswordError(false);
    setAuthError("");

    let validationError = false;

    if (email === "") {
      setEmailError(true);
      validationError = true;
    }

    if (password === "") {
      setPasswordError(true);
      validationError = true;
    }

    if (!validationError) {
      setLoading(true);
      try {
        // Call your login API here
        await login(email, password);
        // If the login is successful, clear any previous error messages
        setAuthError("");
      } catch (error) {
        console.error("Login error:", error);
        // Set the error message received from the server
        setAuthError("Invalid email or password.");
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} sm={8} md={6} lg={4}>
        <Paper elevation={3} sx={{ p: 4, my: 4 }}>
          <Typography variant="h5" component="h1" gutterBottom>
            Login
          </Typography>
          <form onSubmit={handleSubmit}>
            <FormControl
              fullWidth
              error={!!error?.non_field_errors || emailError}
              margin="normal"
            >
              <TextField
                label="Email"
                variant="outlined"
                error={!!error?.non_field_errors || emailError}
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              {emailError && (
                <FormHelperText>Email is required.</FormHelperText>
              )}
            </FormControl>
            <FormControl
              fullWidth
              error={!!error?.non_field_errors || passwordError}
              margin="normal"
            >
              <TextField
                label="Password"
                variant="outlined"
                error={!!error?.non_field_errors || passwordError}
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              {passwordError && (
                <FormHelperText>Password is required.</FormHelperText>
              )}
              {!!error?.non_field_errors && (
                <FormHelperText>{error.non_field_errors}</FormHelperText>
              )}
            </FormControl>
            {authError && (
              <Typography color="error" variant="body2">
                {authError}
              </Typography>
            )}
            <LoadingButton
              size="small"
              type="submit"
              loading={loading}
              variant="contained"
              color="primary"
              fullWidth
            >
              <span>Login</span>
            </LoadingButton>
          </form>
          <Typography variant="body2" sx={{ mt: 2 }}>
            Don't have an account? <Link to="/signup">Sign Up</Link>
          </Typography>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default Login;
