import React, { FC, useEffect, useState } from "react";

import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  Button,
  Container,
  IconButton,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";

import { useCollaboration } from "../contexts/CollaborationContext";
import {
  InCollaborator,
  InInvite,
  OutCollaborator,
  OutInvite
} from "../types/collaboration";

const CollaborationPage: FC = () => {
  const {
    getOutcomingInvitesList,
    sendCollaborationInvite,
    deleteOutcomingInvite,
    getIncomingInvitesList,
    acceptInvite,
    rejectInvite,
    getInCollaborationsList,
    getOutCollaborationsList,
    deleteCollaborator,
    sendingInviteError
  } = useCollaboration();

  const [newInviteEmail, setNewInviteEmail] = useState<string>("");
  const [inInvites, setInInvites] = useState<InInvite[]>([]);
  const [outInvites, setOutInvites] = useState<OutInvite[]>([]);
  const [outCollaborators, setOutCollaborators] = useState<OutCollaborator[]>(
    []
  );
  const [inCollaborators, setInCollaborators] = useState<InCollaborator[]>([]);
  const [deletedOutInviteEmails, setDeletedOutInviteEmails] = useState<
    string[]
  >([]);

  const newInviteStyle = {
    backgroundColor: "background.paper"
  };

  useEffect(() => {
    fetchInInvites();
    fetchOutInvites();
    fetchInCollaborators();
    fetchOutCollaborators();
  }, []);

  const fetchInInvites = async () => {
    const innvites = await getIncomingInvitesList();
    setInInvites(innvites);
  };
  const fetchOutInvites = async () => {
    const innvites = await getOutcomingInvitesList();
    setOutInvites(innvites);
  };
  const fetchInCollaborators = async () => {
    const collaborators = await getInCollaborationsList();
    setInCollaborators(collaborators);
  };
  const fetchOutCollaborators = async () => {
    const collaborators = await getOutCollaborationsList();
    setOutCollaborators(collaborators);
  };

  const handleSendInvite = async () => {
    if (newInviteEmail.length > 0) {
      const newInvite = await sendCollaborationInvite(newInviteEmail);
      if ("user_to" in newInvite === false) {
        return;
      }
      setDeletedOutInviteEmails(
        deletedOutInviteEmails.filter(
          (item) => item !== newInvite.user_to.email
        )
      );
      setOutInvites([
        newInvite,
        ...outInvites.filter(
          (item) => item.user_to.email !== newInvite.user_to.email
        )
      ]);
      setNewInviteEmail("");
    }
  };

  const handleDeleteOutInvite = async (userToEmail: string) => {
    await deleteOutcomingInvite(userToEmail);
    setDeletedOutInviteEmails([...deletedOutInviteEmails, userToEmail]);
  };

  const handleAcceptInvite = async (userFromEmail: string) => {
    await acceptInvite(userFromEmail);
    setInInvites(
      inInvites.filter((item) => item.user_from.email !== userFromEmail)
    );
    fetchOutCollaborators();
  };

  const handleRejectInvite = async (userFromEmail: string) => {
    await rejectInvite(userFromEmail);
    setInInvites(
      inInvites.filter((item) => item.user_from.email !== userFromEmail)
    );
  };

  const handleDeleteCollaborator = async (
    userEmail: string,
    incoming: boolean
  ) => {
    await deleteCollaborator(userEmail, incoming);
    if (incoming) {
      setInCollaborators(
        inCollaborators.filter((item) => item.user.email !== userEmail)
      );
    } else {
      setOutCollaborators(
        outCollaborators.filter((item) => item.user_to.email !== userEmail)
      );
    }
  };

  return (
    <Container sx={{ display: "flex", mt: 4, mb: 4 }}>
      <Grid2 container spacing={3}>
        <Grid2 xs={12}>
          <Stack direction="row" spacing={3}>
            <TextField
              error={sendingInviteError !== ""}
              label="Email of the user to share"
              variant="outlined"
              fullWidth
              value={newInviteEmail}
              helperText={sendingInviteError}
              onChange={(e) => setNewInviteEmail(e.target.value)}
            />
            <Button
              variant="contained"
              onClick={handleSendInvite}
              sx={{ mt: 2 }}
            >
              Invite
            </Button>
          </Stack>
        </Grid2>

        <Grid2 container xs={12}>
          <Grid2 xs={12} md={6}>
            <h3>Invites From Users</h3>
            {inInvites.map((invite) => (
              <Grid2 key={invite.user_from.email}>
                <Stack
                  direction="row"
                  spacing={2}
                  alignItems="center"
                  sx={{ px: 3, ...(invite.viewed ? {} : newInviteStyle) }}
                >
                  <Typography>{invite.user_from.email}</Typography>
                  <Typography>({invite.user_from.username})</Typography>
                  <Stack direction="row" spacing={0} justifyContent="flex-end">
                    <IconButton
                      onClick={() => {
                        handleAcceptInvite(invite.user_from.email);
                      }}
                    >
                      <AddIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => {
                        handleRejectInvite(invite.user_from.email);
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Stack>
                </Stack>
              </Grid2>
            ))}
          </Grid2>
          <Grid2 xs={12} md={6}>
            <h3>Outcoming Invites</h3>

            {outInvites.map((invite) => (
              <Grid2 key={invite.user_to.email}>
                <Stack
                  direction="row"
                  spacing={2}
                  alignItems="center"
                  sx={{ px: 3, ...(invite.viewed ? {} : newInviteStyle) }}
                >
                  <Typography>{invite.user_to.email}</Typography>
                  <Typography>({invite.user_to.username})</Typography>
                  {deletedOutInviteEmails.includes(invite.user_to.email) ? (
                    <Typography variant="overline" color="secondary">
                      Deleted
                    </Typography>
                  ) : (
                    <IconButton
                      onClick={() =>
                        handleDeleteOutInvite(invite.user_to.email)
                      }
                    >
                      <DeleteIcon />
                    </IconButton>
                  )}
                </Stack>
              </Grid2>
            ))}
          </Grid2>
        </Grid2>
        <Grid2 container xs={12}>
          <Grid2 xs={12} md={6}>
            <h3>Access to other users menus</h3>
            {outCollaborators.map((invite) => (
              <Stack
                key={invite.user_to.email}
                direction="row"
                spacing={2}
                alignItems="center"
              >
                <Typography>{invite.user_to.email}</Typography>
                <Typography>({invite.user_to.username})</Typography>
                <IconButton
                  onClick={() => {
                    handleDeleteCollaborator(invite.user_to.email, false);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </Stack>
            ))}
          </Grid2>
          <Grid2 xs={12} md={6}>
            <h3>Users with access to my menus</h3>
            {inCollaborators.map((invite) => (
              <Stack
                key={invite.user.email}
                direction="row"
                spacing={2}
                alignItems="center"
              >
                <Typography>{invite.user.email}</Typography>
                <Typography>({invite.user.username})</Typography>
                <IconButton
                  onClick={() => {
                    handleDeleteCollaborator(invite.user.email, true);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </Stack>
            ))}
          </Grid2>
        </Grid2>
      </Grid2>
    </Container>
  );
};

export default CollaborationPage;
