import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import LoadingAnimation from "../components/LoadingAnimation";
import RecipeDetails from "../components/RecipeDetails";
import { useCollaboration } from "../contexts/CollaborationContext";
import useMenus from "../hooks/useMenus";
import { WeekDayMenuRecipe } from "../types/menu";

interface WeekDayMenuRecipePageParams {
  year: number;
  week: number;
  day: number;
  recipeId: number;
}

const WeekDayMenuRecipePage = () => {
  const { year, week, day, recipeId } =
    useParams<keyof WeekDayMenuRecipePageParams>();
  const { activeCollaboratorUser } = useCollaboration();
  const { getWeekDayMenuRecipe } = useMenus(activeCollaboratorUser.id);

  const [weekDayRecipe, setWeekDayRecipe] = useState<WeekDayMenuRecipe | null>(
    null
  );

  useEffect(() => {
    const fetchRecipe = async (
      year: number,
      week: number,
      day: number,
      recipeId: number
    ) => {
      const data = await getWeekDayMenuRecipe(year, week, day, recipeId);
      setWeekDayRecipe(data);
    };

    if (year && week && day && recipeId) {
      fetchRecipe(
        parseInt(year),
        parseInt(week),
        parseInt(day),
        parseInt(recipeId)
      );
    }
  }, [year, week, day, recipeId]);

  if (weekDayRecipe === null) {
    return <LoadingAnimation />;
  }

  return (
    <RecipeDetails
      recipe={weekDayRecipe.recipe}
      publicRecipe={false}
      servings={weekDayRecipe.servings}
    />
  );
};

export default WeekDayMenuRecipePage;
