import React, { FC, MouseEvent, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";

import MenuIcon from "@mui/icons-material/Menu";
import {
  AppBar,
  Avatar,
  Box,
  Container,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Toolbar,
  Tooltip,
  Typography
} from "@mui/material";

import { useUser } from "../contexts/UserContext";
import { useAuth } from "../hooks/useAuth";

const TopNavBar: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { isAuthenticated, user } = useUser();
  const { logout } = useAuth();
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

  const notAuthMenuItems = [
    { title: "About", path: "/about" },
    { title: "Login", path: "/login" },
    { title: "Sign Up", path: "/signup" }
  ];

  const authMenuItems = [
    { title: "Home", path: "/menu" },
    { title: "Cook Book", path: "/cook-book" }
  ];

  const userMenuItems = [
    { title: "Collaboration", path: "/account/collaboration" },
    { title: "Settings", path: "/account/settings" }
  ];

  const menuItems = isAuthenticated ? authMenuItems : notAuthMenuItems;

  const handleOpenNavMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleMenuItemClick = (url: string) => {
    handleCloseNavMenu();
    navigate(url);
  };

  const handleUserMenuItemClick = (url: string) => {
    handleCloseUserMenu();
    navigate(url);
  };

  const handleLogout = async () => {
    handleCloseUserMenu();
    await logout();
    // navigate(0);
  };

  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar variant="dense" disableGutters sx={{ alignItems: "center" }}>
          <Typography
            noWrap
            component="a"
            href="/"
            sx={{
              display: { xs: "none", md: "flex" },
              fontFamily: "Handlee",
              fontWeight: 700,
              fontSize: "2.5em",
              letterSpacing: ".1rem",
              textDecoration: "none",
              ml: 3,
              mr: 10,
              color: "text.secondary"
            }}
          >
            Eat'z easy
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left"
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left"
              }}
              open={Boolean(anchorElNav)}
              onClose={() => handleCloseNavMenu()}
              sx={{
                display: { xs: "block", md: "none" }
              }}
            >
              {menuItems.map((item) => (
                <MenuItem
                  key={item.title}
                  component={NavLink}
                  to={item.path}
                  onClick={() => handleCloseNavMenu()}
                  selected={location.pathname.startsWith(item.path)}
                >
                  <Typography textAlign="center">{item.title}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Typography
            variant="h5"
            noWrap
            component="a"
            href=""
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontFamily: "Handlee",
              fontSize: "2.2em",
              fontWeight: 700,
              letterSpacing: ".1rem",
              color: "text.secondary",
              textDecoration: "none"
            }}
          >
            Eat'z Easy
          </Typography>
          <Stack
            direction="row"
            spacing={2}
            sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}
          >
            {menuItems.map((item) => (
              <MenuItem
                key={item.title}
                component={NavLink}
                to={item.path}
                selected={location.pathname.startsWith(item.path)}
                sx={{
                  textTransform: "uppercase",
                  color: "text.secondary"
                }}
              >
                {item.title}
              </MenuItem>
            ))}
          </Stack>

          {isAuthenticated && (
            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar>{user?.email[0].toUpperCase()}</Avatar>
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right"
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right"
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {userMenuItems.map((item) => (
                  <MenuItem
                    key={item.title}
                    component={NavLink}
                    to={item.path}
                    onClick={() => handleCloseUserMenu()}
                    selected={location.pathname === item.path}
                  >
                    <Typography textAlign="center">{item.title}</Typography>
                  </MenuItem>
                ))}
                <Divider />
                <MenuItem key="Logout" onClick={handleLogout}>
                  <Typography textAlign="center">Logout</Typography>
                </MenuItem>
              </Menu>
            </Box>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default TopNavBar;
