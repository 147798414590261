import React from "react";

import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent
} from "@mui/material";

interface DayServingsProps {
  value: number;
  onChange: (serving: number) => void;
}

const DayServings: React.FC<DayServingsProps> = ({ value, onChange }) => {
  const handleSelectChange = (event: SelectChangeEvent<number>) => {
    onChange(event.target.value as number);
  };

  return (
    <FormControl>
      <InputLabel>Servings</InputLabel>
      <Select
        value={value}
        onChange={handleSelectChange}
        size="medium"
        sx={{ minWidth: "105px", textAlign: "center" }}
        label="Servings"
      >
        <MenuItem value={0}>Not set</MenuItem>
        {[...Array(10)].map((_, index) => (
          <MenuItem key={index} value={index + 1}>
            {index + 1}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default DayServings;
