import { Dayjs } from "dayjs";
// Snippet used from https://mui.com/x/react-date-pickers/custom-field/
import React, { useState } from "react";

import { Button, ButtonProps } from "@mui/material";
import {
  BaseSingleInputFieldProps,
  DatePicker,
  DatePickerProps,
  DateValidationError,
  FieldSection,
  UseDateFieldProps
} from "@mui/x-date-pickers";

interface ButtonFieldProps
  extends UseDateFieldProps<Dayjs>,
    BaseSingleInputFieldProps<Dayjs | null, FieldSection, DateValidationError>,
    Pick<ButtonProps, "variant"> {
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

interface ButtonDatePickerProps
  extends Omit<DatePickerProps<Dayjs>, "open" | "onOpen" | "onClose"> {
  variant?: ButtonProps["variant"];
}

function ButtonField(props: ButtonFieldProps) {
  const {
    setOpen,
    label,
    id,
    disabled,
    variant,
    InputProps: { ref } = {},
    inputProps: { "aria-label": ariaLabel } = {}
  } = props;

  return (
    <Button
      variant={variant}
      id={id}
      disabled={disabled}
      ref={ref}
      aria-label={ariaLabel}
      onClick={() => setOpen?.((prev) => !prev)}
    >
      {label ?? "Pick a date"}
    </Button>
  );
}

function ButtonDatePicker(props: ButtonDatePickerProps) {
  const [open, setOpen] = useState(false);

  return (
    <DatePicker
      slots={{ field: ButtonField, ...props.slots }}
      slotProps={{
        field: { setOpen, variant: props.variant || "outlined" } as any
      }}
      {...props}
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
    />
  );
}

export default ButtonDatePicker;
