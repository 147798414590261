import { Dayjs } from "dayjs";
import React, { FC, useState } from "react";
import { useDrop } from "react-dnd";
import { useNavigate } from "react-router-dom";

import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  ListItem,
  Typography,
  useTheme
} from "@mui/material";
import Chip from "@mui/material/Chip";
import { Stack } from "@mui/system";

import { ItemTypes } from "../types/DnD";
import { WeekDayMenu } from "../types/menu";
import { Recipe } from "../types/recipe";
import DayServings from "./DayServings";
import { MoreModal } from "./MoreModal";

interface DayOfWeekProps {
  year: number;
  week: number;
  day: Dayjs;
  servings: number;
  recipesList: WeekDayMenu[];
  handleServingsChange: (servings: number) => void;
  handleAdd: (year: number, week: number, day: number, recipe: Recipe) => void;
  handleDelete: (
    year: number,
    week: number,
    day: number,
    recipeId: number
  ) => void;
}

const numberOfLines = 5;

export const DayOfWeek: FC<DayOfWeekProps> = ({
  year,
  week,
  day,
  servings,
  recipesList,
  handleServingsChange,
  handleAdd,
  handleDelete
}: DayOfWeekProps) => {
  const navigate = useNavigate();
  const theme = useTheme();

  // DnD
  const onDrop = async (recipe: Recipe) => {
    console.log("Dropped recipe", recipe);
    if (recipe.id !== null) {
      handleAdd(year, week, day.day(), recipe);
    }
  };

  const [{ isOver, canDrop }, drop] = useDrop({
    accept: ItemTypes.MenuPlannerRecipe,
    drop: onDrop,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    })
  });

  const [open, setOpen] = useState(false);

  const handleClick = (recipeId: number) => {
    navigate(`/cook-book/${recipeId}`);
  };

  const handleMoreOpen = () => {
    setOpen(true);
  };

  const handleMoreClose = (value: string) => {
    setOpen(false);
  };

  const onDelete = (recipeId: number) => {
    handleDelete(year, week, day.day(), recipeId);
  };

  return (
    <Card
      variant="outlined"
      sx={{
        minHeight: "220px",
        backgroundColor: canDrop
          ? theme.palette.primary.dark
          : theme.palette.background.paper,
        borderColor: isOver ? "lightgreen" : theme.palette.background.paper,
        borderWidth: isOver ? "2px" : "none",
        width: "auto",
        mx: 1,
        pt: 0
      }}
      ref={drop}
    >
      <CardContent sx={{ p: 2 }}>
        <DayServings value={servings} onChange={handleServingsChange} />

        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ mb: 2 }}
        >
          <Avatar
            variant="rounded"
            alt={day.format("ddd").toUpperCase()}
            sx={{
              bgcolor: "secondary.main",
              fontSize: "1em"
              // color: "#b0bec5"
            }}
          >
            {day.format("ddd")}{" "}
          </Avatar>
          <Typography sx={{ color: "#70757a", fontStyle: "italic" }}>
            {day.format("	MMM D")}
          </Typography>
        </Stack>

        {recipesList.map((recipe, line) => {
          if (line < numberOfLines) {
            return (
              <ListItem key={line} dense sx={{ m: 0, p: 0 }}>
                <Chip
                  label={recipe.title}
                  size="small"
                  variant="outlined"
                  color="primary"
                  onClick={() => handleClick(recipe.recipe)}
                  onDelete={() => onDelete(recipe.recipe)}
                  sx={{
                    mb: 1.2,
                    "&.MuiChip-root": {
                      borderRadius: 1
                    }
                  }}
                />
              </ListItem>
            );
          }
        })}
      </CardContent>
      {recipesList.length > numberOfLines && (
        <CardActions sx={{ pt: "0" }}>
          <Button size="small" color="info" onClick={handleMoreOpen}>
            {" "}
            {recipesList.length - numberOfLines + " more"}
          </Button>
          <MoreModal
            open={open}
            onClose={handleMoreClose}
            recipesList={recipesList}
            onDelete={(recipeId: number) => onDelete(recipeId)}
            day={day}
          ></MoreModal>
        </CardActions>
      )}
    </Card>
  );
};
