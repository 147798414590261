import { FC } from "react";

import { Box, LinearProgress } from "@mui/material";

const LoadingAnimation: FC = () => {
  return (
    <Box sx={{ width: "100%" }}>
      <LinearProgress />
    </Box>
  );
};

export default LoadingAnimation;
