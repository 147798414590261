// src/pages/CookBookPage.tsx

import { RefCallback, useCallback, useEffect, useRef, useState } from "react";
import { useDrop } from "react-dnd";
import { useNavigate } from "react-router-dom";

import { Box, Button, Grid, TextField, Typography } from "@mui/material";

import LoadingAnimation from "../components/LoadingAnimation";
import { CardRef, DraggableRecipeCard, RecipeCard } from "../components/RecipeCard";
import { useThemeControl } from "../contexts/CustomThemeContext";
import useRecipes from "../hooks/useRecipes";
import { ItemTypes } from "../types/DnD";
import { Recipe } from "../types/recipe";

const CookBookPage: React.FC = () => {
  const FETCH_LIMIT = 30;
  const navigate = useNavigate();
  const { getMyRecipes, getPublicRecipes, savePublicRecipe, loading } =
    useRecipes();

  const [filterValue, setFilterValue] = useState<string>("");
  const [myRecipes, setMyRecipes] = useState<Recipe[]>([]);
  const [otherRecipes, setOtherRecipes] = useState<Recipe[]>([]);
  const [nextOffsetMyRec, setNextOffsetMyRec] = useState<number | null>(0);
  const [nextOffsetOtherRec, setNextOffsetOtherRec] = useState<number | null>(
    0
  );
  const [loadMoreMyRec, setLoadMoreMyRec] = useState<boolean>(false);
  const [loadMoreOtherRec, setLoadMoreOtherRec] = useState<boolean>(false);
  const { theme } = useThemeControl();

  const myRecipesIntObserver = useRef<IntersectionObserver | null>(null);
  const myRecipeLastRef: RefCallback<CardRef | null> = useCallback(
    (card: CardRef) => {
      if (loading || loadMoreMyRec) {
        return;
      }
      if (myRecipesIntObserver.current) {
        myRecipesIntObserver.current.disconnect();
      }

      myRecipesIntObserver.current = new IntersectionObserver(
        (entries: IntersectionObserverEntry[]) => {
          if (entries[0].isIntersecting && nextOffsetMyRec !== null) {
            setLoadMoreMyRec(true);
          }
        }
      );

      if (card) {
        myRecipesIntObserver.current.observe(card);
      }
    },
    [loading, loadMoreMyRec, nextOffsetMyRec]
  );
  const otherRecipesIntObserver = useRef<IntersectionObserver | null>(null);
  const otherRecipeLastRef: RefCallback<CardRef | null> = useCallback(
    (card: CardRef) => {
      if (loading || loadMoreOtherRec) {
        return;
      }
      if (otherRecipesIntObserver.current) {
        otherRecipesIntObserver.current.disconnect();
      }

      otherRecipesIntObserver.current = new IntersectionObserver(
        (entries: IntersectionObserverEntry[]) => {
          if (entries[0].isIntersecting && nextOffsetOtherRec !== null) {
            setLoadMoreOtherRec(true);
          }
        }
      );

      if (card) {
        otherRecipesIntObserver.current.observe(card);
      }
    },
    [loading, loadMoreOtherRec, nextOffsetOtherRec]
  );

  const onDrop = async (recipe: Recipe) => {
    if (recipe?.id !== null) {
      const newRecipe = await savePublicRecipe(recipe.id);
      setMyRecipes([newRecipe, ...myRecipes]);
    }
  };

  const [{ isOver, canDrop }, drop] = useDrop({
    accept: ItemTypes.OtherRecipe,
    drop: onDrop,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    })
  });

  const isActive = isOver && canDrop;
  let backgroundColor;
  if (isActive) {
    backgroundColor = "darkgreen";
  } else if (canDrop) {
    backgroundColor = "darkkhaki";
  }

  const fetchMyRecipes = async () => {
    if (nextOffsetMyRec !== null) {
      const paginatedRecipes = await getMyRecipes(
        filterValue,
        nextOffsetMyRec,
        FETCH_LIMIT
      );
      setMyRecipes([...myRecipes, ...paginatedRecipes.results]);
      setNextOffsetMyRec(paginatedRecipes.offset);
    }
  };

  const fetchOtherRecipes = async () => {
    if (nextOffsetOtherRec !== null) {
      const paginatedRecipes = await getPublicRecipes(
        // TODO update this call to fetch other recipes
        filterValue,
        nextOffsetOtherRec,
        FETCH_LIMIT
      );
      setOtherRecipes([...otherRecipes, ...paginatedRecipes.results]);
      setNextOffsetOtherRec(paginatedRecipes.offset);
    }
  };

  useEffect(() => {
    if (loadMoreMyRec) {
      fetchMyRecipes();
      setLoadMoreMyRec(false);
    }
  }, [loadMoreMyRec]);

  useEffect(() => {
    if (loadMoreOtherRec) {
      fetchOtherRecipes();
      setLoadMoreOtherRec(false);
    }
  }, [loadMoreOtherRec]);

  useEffect(() => {
    // get my recipes, erase old values first
    setMyRecipes([]);
    setNextOffsetMyRec(0);
    setLoadMoreMyRec(true);
    // get other recipes
    setOtherRecipes([]);
    setNextOffsetOtherRec(0);
    setLoadMoreOtherRec(true);
  }, [filterValue]);

  return (
    <Grid container>
      <Grid item xs={12} sx={{ height: 1 }}>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexGrow="1"
          sx={{ height: "90px" }}
        >
          <TextField
            label="Search recipes"
            value={filterValue}
            onChange={(e) => setFilterValue(e.target.value)}
            variant="outlined"
            sx={{ width: { xs: "100%", md: "600px" } }}
          />
          <Button
            variant="contained"
            sx={{ ml: "30px" }}
            onClick={() => navigate("/cook-book/add")}
          >
            New Recipe
          </Button>
        </Box>
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography
          variant="h5"
          sx={{ textAlign: "center", height: "30px", mb: "10px" }}
        >
          Your Recipes
        </Typography>
        {loadMoreMyRec && <LoadingAnimation />}
        <Box
          ref={drop}
          sx={{
            backgroundColor,
            borderRight: {
              xs: "none",
              md: `1px solid ${theme.palette.divider}`
            },
            paddingRight: { xs: 0, md: 3 },
            overflowY: "auto",
            height: {
              xs: "calc((100vh - 64px - 20px - 90px)/2 - 40px)",
              md: "calc(100vh - 64px - 20px - 90px - 40px)" // 100vh - (navbar height) - (container top padding) - (search bar height) - (area header with margin)
            }
          }}
        >
          <Grid
            container
            spacing={2}
            justifyContent="center"
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(auto-fit, minmax(250px, auto))"
            }}
          >
            {myRecipes.map((recipe, i) => (
              <Grid item key={recipe.id}>
                <RecipeCard
                  recipe={recipe}
                  {...(i === myRecipes.length - 1
                    ? { ref: myRecipeLastRef }
                    : {})}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={12} md={6} sx={{ p: 0 }}>
        <Typography
          variant="h5"
          gutterBottom
          sx={{
            textAlign: "center",
            height: "30px",
            mb: "10px",
            mt: { xs: "10px", md: 0 }
          }}
        >
          All Recipes
        </Typography>
        {loadMoreOtherRec && <LoadingAnimation />}
        <Box
          sx={{
            paddingLeft: { xs: 0, md: 3 },
            overflowY: "auto",
            height: {
              xs: "calc((100vh - 64px - 20px - 90px)/2 - 40px - 10px)",
              md: "calc(100vh - 64px - 20px - 90px - 40px)" // 100vh - (navbar height) - (container top padding) - (search bar height) - (area header with margin)
            }
          }}
        >
          <Grid
            container
            spacing={2}
            justifyContent="center"
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(auto-fit, minmax(250px, auto))"
            }}
          >
            {otherRecipes.map((recipe, i) => (
              <Grid item key={recipe.id}>
                <DraggableRecipeCard
                  recipe={recipe}
                  publicRecipe={true}
                  itemType={ItemTypes.OtherRecipe}
                  {...(i === otherRecipes.length - 1
                    ? { ref: otherRecipeLastRef }
                    : {})}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
};

export default CookBookPage;
