// src/pages/AddRecipePage.tsx

import axios from "axios";
import React, { useState } from "react";

import RecipeForm from "../components/RecipeForm";
import useRecipes from "../hooks/useRecipes";
import { ApiResponeError } from "../types/api";
import { Recipe } from "../types/recipe";

const AddRecipePage: React.FC = () => {
  const { createRecipe, loading } = useRecipes();
  const [formErrors, setFormErrors] = useState<ApiResponeError>({});

  const callCreateRecipe = async (formData: Recipe): Promise<Recipe> => {
    setFormErrors({});
    try {
      const newRecipe = await createRecipe(formData);
      return newRecipe;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        setFormErrors(error.response?.data ?? {});
      }
    }
    return {} as Recipe;
  };

  return (
    <RecipeForm
      title="Add New Recipe"
      onSubmit={callCreateRecipe}
      errors={formErrors}
    />
  );
};

export default AddRecipePage;
