import {
  createContext,
  FC,
  ReactNode,
  useContext,
  useEffect,
  useState
} from "react";

import { useRecipes } from "../hooks/useRecipes";
import { useUser } from "./UserContext";

export interface Unit {
  id: number;
  name: string;
}

interface UnitsContextProps {
  units: Unit[] | null;
  setUnits: (units: Unit[]) => void;
}

interface UnitsContextProviderProps {
  children: ReactNode;
}

export const UnitsContext = createContext<UnitsContextProps>({
  units: null,
  setUnits: () => {}
});

export const UnitsProvider: FC<UnitsContextProviderProps> = ({ children }) => {
  const [units, setUnits] = useState<Unit[]>([]);
  const { getUnits } = useRecipes();
  const { isAuthenticated } = useUser();

  useEffect(() => {
    const fetchUnits = async () => {
      if (units.length === 0) {
        const fetchedUnits = await getUnits();
        setUnits(fetchedUnits);
      }
    };

    if (isAuthenticated) {
      fetchUnits();
    }
  }, [isAuthenticated]);

  return (
    <UnitsContext.Provider value={{ units, setUnits }}>
      {children}
    </UnitsContext.Provider>
  );
};

export function useUnits(): UnitsContextProps {
  const context = useContext(UnitsContext);

  if (context === undefined) {
    throw new Error("useUnits must be used within a UserProvider");
  }

  return context;
}
