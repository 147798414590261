import React, { createContext, FC, useContext, useMemo } from "react";

import Api from "../api/axios";

// Create the context
const ApiContext = createContext<Api | undefined>(undefined);

interface ApiProviderProps {
  children: React.ReactNode;
}

// Create a provider for components to consume and subscribe to changes
export const ApiProvider: FC<ApiProviderProps> = ({ children }) => {
  // Api instance is created only once
  const apiInstance = useMemo(() => new Api(), []);

  return (
    <ApiContext.Provider value={apiInstance}>{children}</ApiContext.Provider>
  );
};

// Custom hook to quickly read the context's value. It's
// only here to allow quick access to the Api instance
const useApi = () => {
  const context = useContext(ApiContext);
  if (context === undefined) {
    throw new Error(`useApi must be used within a ApiProvider`);
  }
  return context;
};

export default useApi;
