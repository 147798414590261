import axios from "axios";
import React, { useRef, useState } from "react";

import useApi from "../contexts/ApiContext";
import { Ingredient } from "../types/recipe";

type UseIngredientsReturnType = {
  searchIngredients: (searchQuery: string) => Promise<Ingredient[]>;
  createIngredient: (name: string) => Promise<Ingredient>;
  loading: boolean;
};

type RequestErrors = {
  [field: string]: string;
};

export const useIngredients = (): UseIngredientsReturnType => {
  const API_URL = "/v1/ingredients";
  const api = useApi();

  const searchIngredientsAbortControllerRef = useRef<AbortController>();

  const [errors, setErrors] = useState<RequestErrors>({});
  const [loading, setLoading] = useState<boolean>(false);

  const searchIngredients = async (searchQuery: string) => {
    const requestUrl = `${API_URL}/?search=${searchQuery}`;
    setErrors({});
    setLoading(true);
    if (searchIngredientsAbortControllerRef.current) {
      searchIngredientsAbortControllerRef.current.abort();
    }
    try {
      const { request, abortController } = api.get<Ingredient[]>(requestUrl);
      searchIngredientsAbortControllerRef.current = abortController;
      const response = await request;
      searchIngredientsAbortControllerRef.current = undefined;
      return response.data;
    } catch (error) {
      searchIngredientsAbortControllerRef.current = undefined;
      if (axios.isCancel(error)) {
        console.log("Cancelled ingredients search request");
        return [] as Ingredient[];
      }
      let errorMessage = "{form: 'Error occured'}";
      if (error instanceof Error) {
        errorMessage = error.message;
      }
      setErrors({ ...errors, ...JSON.parse(errorMessage) });
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const createIngredient = async (name: string) => {
    const requestUrl = `${API_URL}/`;
    setErrors({});
    setLoading(true);
    try {
      const { request } = api.post<Ingredient>(requestUrl, { name });
      const response = await request;
      return response.data;
    } catch (error) {
      let errorMessage = "{form: 'Error occured'}";
      if (error instanceof Error) {
        errorMessage = error.message;
      }
      setErrors({ ...errors, ...JSON.parse(errorMessage) });
      throw error;
    } finally {
      setLoading(false);
    }
  };

  return {
    searchIngredients,
    createIngredient,
    loading
  };
};
