// src/pages/AddRecipePage.tsx

import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import LoadingAnimation from "../components/LoadingAnimation";
import RecipeForm from "../components/RecipeForm";
import useRecipes from "../hooks/useRecipes";
import { ApiResponeError } from "../types/api";
import { Recipe } from "../types/recipe";

type PageParams = {
  recipeId: string;
};

const EditRecipePage: React.FC = () => {
  const { recipeId } = useParams<PageParams>();
  const { updateRecipe, getRecipeById, loading } = useRecipes();
  const [recipe, setRecipe] = useState<Recipe | null>(null);
  const [formErrors, setFormErrors] = useState<ApiResponeError>({});

  useEffect(() => {
    const fetchRecipe = async (recipeId: string | number) => {
      const publicRecipe = false; // explicitly
      const data = await getRecipeById(recipeId, publicRecipe);
      setRecipe(data);
    };

    if (recipeId) {
      fetchRecipe(recipeId);
    }
  }, []);

  const callCreateRecipe = async (formData: Recipe): Promise<Recipe> => {
    setFormErrors({});

    // Refresh the recipe object with the new data to persist it in case of validation errors
    // Otherwise the form will be reset to the original recipe data and we loose the user input
    setRecipe(formData);

    try {
      return await updateRecipe(formData);
    } catch (error) {
      if (error instanceof axios.AxiosError) {
        setFormErrors(error.response?.data ?? {});
      }
    }
    return {} as Recipe;
  };

  if (loading) {
    return <LoadingAnimation />;
  }

  return (
    <RecipeForm
      title={`Edit recipe "${recipe?.title}"`}
      onSubmit={callCreateRecipe}
      errors={formErrors}
      initialValues={recipe}
    />
  );
};

export default EditRecipePage;
