import React, { FC } from "react";

import { Editor } from "@tinymce/tinymce-react";

interface TextEditorProps {
  value: string;
  handleChange: (value: string) => void;
}

const TextEditor: FC<TextEditorProps> = ({ value, handleChange }) => {
  return (
    <Editor
      apiKey="dd3qw5eux2rmdb6bz5lsng3m0nmzqkdx0uofg7rbwvtux100"
      init={{
        placeholder: "Steps and details..."
      }}
      plugins="advlist autolink lists link preview table"
      toolbar="undo redo | h1 h2 h3 | styles | bold italic | alignleft aligncenter alignright alignjustify | outdent indent | link | bullist numlist | table | preview"
      value={value}
      onEditorChange={(val) => {
        console.log(val);
        handleChange(val);
      }}
    />
  );
};

export default TextEditor;
