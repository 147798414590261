import React from "react";
import { Outlet, useLocation } from "react-router";
import { NavLink } from "react-router-dom";

import DiningIcon from "@mui/icons-material/Dining";
import GroupIcon from "@mui/icons-material/Group";
import Person2Icon from "@mui/icons-material/Person2";
import SettingsIcon from "@mui/icons-material/Settings";
import { Box } from "@mui/material";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Stack } from "@mui/system";

const AccountPage = () => {
  const tabItems = [
    {
      title: "Servings",
      path: "/account/servings",
      icon: <DiningIcon />
    },
    {
      title: "Collaboration",
      path: "/account/collaboration",
      icon: <GroupIcon />
    },
    { title: "Settings", path: "/account/settings", icon: <SettingsIcon /> }
  ];
  const location = useLocation();

  return (
    <Stack
      direction="row"
      justifyContent="flex-start"
      alignItems="stretch"
      spacing={2}
      sx={{ width: "100%" }}
    >
      <List
        component="nav"
        aria-label="Account folders"
        sx={{ height: "100vh" }}
      >
        {tabItems.map((item) => (
          <ListItemButton
            key={item.title}
            component={NavLink}
            to={item.path}
            selected={location.pathname === item.path}
          >
            <ListItemIcon>{item.icon}</ListItemIcon>

            <ListItemText primary={item.title} />
          </ListItemButton>
        ))}
      </List>
      <Box sx={{ width: "100%" }}>
        <Outlet />
      </Box>
    </Stack>
  );
};

export default AccountPage;
