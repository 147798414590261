import dayjs, { Dayjs } from "dayjs";
import isoWeek from "dayjs/plugin/isoWeek";
import weekday from "dayjs/plugin/weekday";
import { useMemo } from "react";

dayjs.extend(isoWeek);
dayjs.extend(weekday);

interface DateUtils {
  getCurrentDate: () => Dayjs;
  getFirstDayOfWeek: (date: Dayjs) => Dayjs,
  getWeekNumber: (date: Dayjs) => number;
  getYear: (date: Dayjs) => number;
  getWeekDates: (year: number, weekNumber: number) => Dayjs[];
  getMondayByWeekNumber: (year: number, weekNumber: number) => Dayjs;
  getCurrentWeekDates: () => Dayjs[];
}

const useDateUtils = (): DateUtils => {

  const getCurrentDate = (): Dayjs => {
    return dayjs();
  };

  const getFirstDayOfWeek = (date: Dayjs): Dayjs => {
    return date.isoWeekday(1);
}

  const getWeekNumber = (date: Dayjs): number => {
    return date.isoWeek()
  };

  const getWeekDates = (year: number, weekNumber: number): Dayjs[] => {
    const firstWeekDay = getMondayByWeekNumber(year, weekNumber);
    return [...Array(7).keys()].map(n => firstWeekDay.weekday(n));
  }

  function getMondayByWeekNumber(year: number, weekNumber: number): Dayjs {
    // Create a Day.js object for January 4th of the given year
    // January 4th is always in ISO week 1
    const jan4 = dayjs(`${year}-01-04`);
    // Set the ISO week number and set the ISO weekday to Monday (1)
    const desiredMonday = jan4.isoWeek(weekNumber).isoWeekday(1);
    return desiredMonday;
  }

  const getYear = (date: Dayjs): number => {
    return date.isoWeekYear();
  };

  const getCurrentWeekDates = (): Dayjs[] => {
    const today = getCurrentDate()
    const year = getYear(today)
    const week = getWeekNumber(today)
    return getWeekDates(year, week)
  }


  return useMemo(() => {
    return {
      getCurrentDate,
      getFirstDayOfWeek,
      getMondayByWeekNumber,
      getWeekNumber,
      getYear,
      getWeekDates,
      getCurrentWeekDates,
    };
  }, []);
};

export default useDateUtils;
