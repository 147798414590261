import axios from "axios";
import React, { useRef, useState } from "react";

import useApi from "../contexts/ApiContext";
import {
  WeekDayMenu,
  WeekDayMenuMap,
  WeekDayMenuRecipe,
  WeekDayServing,
  WeekMenu
} from "../types/menu";

interface UseMenus {
  getWeekMenu: (year: number, week: number) => Promise<WeekDayMenuMap>;
  addRecipeToDayMenu: (
    year: number,
    week: number,
    day: number,
    recipe: number
  ) => Promise<WeekDayMenu>;
  deleteRecipeFromMenu: (
    year: number,
    week: number,
    day: number,
    recipeId: number
  ) => Promise<boolean>;
  cancelGetWeekMenu: () => void;
  getWeekServings: (year: number, week: number) => Promise<WeekDayServing[]>;
  updateWeekServings: (
    year: number,
    week: number,
    day: number,
    servings: number
  ) => Promise<WeekDayServing>;
  getWeekDayMenuRecipe: (
    year: number,
    week: number,
    day: number,
    recipeId: number
  ) => Promise<WeekDayMenuRecipe>;
  loading: boolean;
}

const useMenus = (userId: number): UseMenus => {
  const API_URL = `/v1/menus/${userId}`;
  const api = useApi();

  const [loading, setLoading] = useState<boolean>(false);

  let getWeekMenuAbortControllerRef = useRef<AbortController>();

  const _createWeekMenu = (dayRecipes: WeekDayMenu[]): WeekDayMenuMap => {
    const weekMenu: WeekDayMenuMap = {};
    const days: WeekDayMenu[] = dayRecipes;
    days.forEach((item: WeekDayMenu) => {
      if (!(item.day_number in weekMenu)) {
        weekMenu[item.day_number] = [];
      }
      weekMenu[item.day_number].push(item);
    });
    return weekMenu;
  };

  const getWeekMenu = async (
    year: number,
    week: number
  ): Promise<WeekDayMenuMap> => {
    const requestUrl = `${API_URL}/${year}/${week}/`;

    setLoading(true);

    const { request, abortController } = api.get<WeekMenu>(requestUrl);

    getWeekMenuAbortControllerRef.current = abortController;

    try {
      const response = await request;
      // handle the response
      getWeekMenuAbortControllerRef.current = undefined;
      return _createWeekMenu(response.data.days ?? []);
    } catch (error) {
      getWeekMenuAbortControllerRef.current = undefined;
      if (axios.isCancel(error)) {
        console.log("Request was cancelled");
        return {} as Promise<WeekDayMenuMap>;
      } else {
        throw error;
      }
    } finally {
      setLoading(false);
    }
  };

  const cancelGetWeekMenu = () => {
    if (getWeekMenuAbortControllerRef.current) {
      getWeekMenuAbortControllerRef.current.abort();
    }
  };

  const addRecipeToDayMenu = async (
    year: number,
    week: number,
    day: number,
    recipeId: number
  ): Promise<WeekDayMenu> => {
    const requestUrl = `${API_URL}/${year}/${week}/${day}/`;
    const { request } = api.post<WeekDayMenu>(requestUrl, { recipe: recipeId });

    setLoading(true);
    try {
      const response = await request;
      return response.data;
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const deleteRecipeFromMenu = async (
    year: number,
    week: number,
    day: number,
    recipeId: number
  ): Promise<boolean> => {
    const requestUrl = `${API_URL}/${year}/${week}/${day}/${recipeId}/`;
    const { request } = api.delete(requestUrl);
    try {
      await request;
      return true;
    } catch (error) {
      // TODO: FIXME!!!
      console.error(error);
    }
    return false;
  };

  const getWeekServings = async (
    year: number,
    week: number
  ): Promise<WeekDayServing[]> => {
    const requestUrl = `${API_URL}/${year}/${week}/servings/`;
    const { request } = api.get<WeekDayServing[]>(requestUrl);
    try {
      const response = await request;
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const updateWeekServings = async (
    year: number,
    week: number,
    day: number,
    servings: number
  ): Promise<WeekDayServing> => {
    const requestUrl = `${API_URL}/${year}/${week}/${day}/servings/`;
    const { request } = api.post<WeekDayServing>(requestUrl, {
      number: servings
    });
    try {
      const response = await request;
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const getWeekDayMenuRecipe = async (
    year: number,
    week: number,
    day: number,
    recipeId: number
  ): Promise<WeekDayMenuRecipe> => {
    const requestUrl = `${API_URL}/${year}/${week}/${day}/recipe/${recipeId}`;
    const { request } = api.get<WeekDayMenuRecipe>(requestUrl);
    try {
      const response = await request;
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  return {
    getWeekMenu,
    addRecipeToDayMenu,
    deleteRecipeFromMenu,
    cancelGetWeekMenu,
    getWeekServings,
    updateWeekServings,
    getWeekDayMenuRecipe,
    loading
  };
};

export default useMenus;
