import { Dayjs } from "dayjs";
import React, { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {
  Box,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

import ButtonDatePicker from "../components/ButtonDatepicker";
import { useCollaboration } from "../contexts/CollaborationContext";
import { useUser } from "../contexts/UserContext";
import useDateUtils from "../hooks/useDateUtils";
import { User } from "../types/user";
import { generateMenuUrl } from "./LayoutMenu";

interface DatepickerBarProps {
  weekDates: Dayjs[];
  children?: string | JSX.Element | JSX.Element[];
  urlSuffix?: string;
}

export const DatepickerBar: FC<DatepickerBarProps> = ({
  weekDates,
  children,
  urlSuffix
}) => {
  const navigate = useNavigate();
  const { getWeekNumber, getYear, getCurrentDate } = useDateUtils();
  const {
    getOutCollaborationsList,
    activeCollaboratorUser,
    changeActiveCollaborator
  } = useCollaboration();
  const { user } = useUser();

  const [collaboratorUsers, setCollaboratorUsers] = useState<User[] | null>(
    null
  );

  useEffect(() => {
    const fetchOutCollaborators = async () => {
      const collaborators = await getOutCollaborationsList();
      setCollaboratorUsers(
        collaborators.map((collaborator) => collaborator.user_to)
      );
    };

    fetchOutCollaborators();
  }, []);

  const navigateToSelectedWeek = (date: Dayjs) => {
    const year: number = getYear(date);
    const week: number = getWeekNumber(date);
    console.log("Redirect to:", year, week);
    navigate(
      generateMenuUrl(
        activeCollaboratorUser.username,
        year.toString(),
        week.toString(),
        urlSuffix
      ),
      { replace: true }
    );
  };

  const navigateToPreNextWeek = (daysDelta: 7 | -7) => {
    // TODO(mrgoodkat): We get a second day because for some reason the week starts from Sunday now
    const secondDay = weekDates[1];
    const targetWeekDay = secondDay.add(daysDelta, "day");
    navigate(
      generateMenuUrl(
        activeCollaboratorUser.username,
        getYear(targetWeekDay).toString(),
        getWeekNumber(targetWeekDay).toString(),
        urlSuffix
      )
    );
  };

  const handleChangeCollaborator = (e: SelectChangeEvent) => {
    const collaborator =
      collaboratorUsers?.find((item) => item.id === parseInt(e.target.value)) ??
      user;

    changeActiveCollaborator(collaborator);
    const secondDay = weekDates[1];
    navigate(
      generateMenuUrl(
        collaborator.username,
        getYear(secondDay).toString(),
        getWeekNumber(secondDay).toString(),
        urlSuffix
      )
    );
  };

  return (
    <Grid container alignItems="center">
      <Grid xs={12} sm={3}>
        <Box display="flex" justifyContent="flex-start">
          {/* A placeholder to align by center the datepicker in the middle cell */}
          {collaboratorUsers !== null && (
            <FormControl
              variant="standard"
              sx={{ minWidth: 150, maxWidth: 200 }}
            >
              <Select
                value={activeCollaboratorUser.id.toString()}
                onChange={handleChangeCollaborator}
                sx={{ paddingLeft: 2 }}
              >
                <MenuItem value={user.id}>{user.username}</MenuItem>
                {collaboratorUsers.map((collaborator) => (
                  <MenuItem key={collaborator.id} value={collaborator.id}>
                    {collaborator.username}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </Box>
      </Grid>
      <Grid xs={8} sm>
        <Stack
          display="flex"
          justifyContent={{ xs: "center", sm: "center" }}
          direction="row"
        >
          <IconButton
            area-aria-label="Previous week"
            onClick={() => navigateToPreNextWeek(-7)}
            centerRipple={false}
            sx={{
              borderRadius: "4px"
            }}
          >
            <NavigateBeforeIcon color="primary" />
          </IconButton>
          <ButtonDatePicker
            label={`${weekDates[0].format("l")} - ${weekDates[
              weekDates.length - 1
            ].format("l")}`}
            variant="text"
            value={weekDates.length > 0 ? weekDates[0] : getCurrentDate()}
            onChange={(date) => date && navigateToSelectedWeek(date)}
            showDaysOutsideCurrentMonth
          />
          <IconButton
            area-aria-label="Next week"
            onClick={() => navigateToPreNextWeek(7)}
            centerRipple={false}
            sx={{
              borderRadius: "4px"
            }}
          >
            <NavigateNextIcon color="primary" />
          </IconButton>
        </Stack>
      </Grid>
      <Grid xs={4} sm={3}>
        <Box display="flex" justifyContent={{ xs: "center", sm: "flex-end" }}>
          {children}
        </Box>
      </Grid>
    </Grid>
  );
};
