import axios, { AxiosProgressEvent } from "axios";

import { BASE_URL } from "../api/axios";
import { useUser } from "../contexts/UserContext";

export interface UploadApiRequest {
  (
    url: string,
    files: { [field: string]: File },
    updateProgress: (progress: number) => void,
    onUpload: (data: UploadApiResponse) => void,
    abortController: AbortController
  ): Promise<any>;
}

export type UploadApiResponse = {
  // Couldn't make dynamic and static keys with one interface
  id: number;
  file: string;
};

const useFileUpload = (): UploadApiRequest => {
  const { user } = useUser();
  const token = user?.token;

  const uploadFile: UploadApiRequest = async (
    url: string,
    files: { [field: string]: File },
    updateProgress: (progress: number) => void,
    onUpload: (data: UploadApiResponse) => void,
    abortController: AbortController
  ) => {
    // Convert JSON to FormData
    const formData: FormData = new FormData();
    Object.entries(files).map(([fieldName, file]) => {
      formData.append(fieldName, file);
    });

    await axios
      .post(`${BASE_URL}${url}`, formData, {
        headers: {
          Authorization: `Token ${token}`,
          "Content-Type": "multipart/form-data"
        },
        signal: abortController.signal,
        onUploadProgress: (progressEvent: AxiosProgressEvent) => {
          const { loaded, total } = progressEvent;
          const progress = Math.floor((loaded * 100) / (total ?? 1));
          updateProgress(progress);
        },
        onDownloadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          const progress = Math.floor((loaded * 100) / (total ?? 1));
          updateProgress(progress);
        }
      })
      .then((response) => onUpload(response.data))
      .catch((err) => {
        if (err.name === "CanceledError" || err.name === "AbortError") {
          console.error("Upload request cancelled.");
          return;
        }
      });
  };

  return uploadFile;
};

export default useFileUpload;
